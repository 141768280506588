import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";
import logo from "../assets/images/logos/sidebar/logo.png"
import style from "./../assets/styles/Layout.module.css";
import {
	LOGGED_IN_ROUTER,
	LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN,

	LOGGED_IN_ROUTER_STORE_MAIN_SCREEN,
} from "./NavigationConstants";
import patientList from "../assets/images/logos/sidebar/white/patientList.svg"
import {FormattedMessage} from "react-intl";

/**
 * Title >
 * 					Icon: Icona del titolo in alto a sinistra
 * 					Text: Titolo del progetto/menu
 * items > array di :
 * 											TItle: titolo del sottomenu
 *                      items: voci del menu > array di:
 *                                                        id: identificativo per indifviduare voce attiva
 *                                                        icon: icona della voce di menu
 *                                                        text: testo della voce di menu
 *
 */
export const MENU_ELEMENTS = {
	logo:{
		src: logo,
		alternativeIcon: <AccountCircleIcon />,
		alternativeText: <FormattedMessage id="sidebar.menu.title" />,
		path: LOGGED_IN_ROUTER_STORE_MAIN_SCREEN.PATH
	},
	items: [
		{
			title: "",
			items: [
				{
					id: LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN.ID,
					icon: <img src={patientList} className={style.menuIcon} alt={""}/>,
					text: <FormattedMessage id="sidebar.menu.patientList" />,
					path: LOGGED_IN_ROUTER.PATH+LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN.PATH
				},
				{
					id: "",
					icon: <img src={patientList} className={style.menuIcon} alt={""}/>,
					text: <FormattedMessage id="sidebar.menu.dashboard" /> ,
					path: "",
					disabled: true
				},
				{
					id: "",
					icon: <img src={patientList} className={style.menuIcon} alt={""}/>,
					text: <FormattedMessage id="sidebar.menu.helpCenter" />,
					path: "",
					disabled: true
				},
				{
					id: "",
					icon: <img src={patientList} className={style.menuIcon} alt={""}/>,
					text: <FormattedMessage id="sidebar.menu.options" />,
					path: "",
					disabled: true
				}

			]
		}
	]
};
