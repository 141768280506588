import {ALPHABET_LIST_LETTERS, NO_SEARCH_RESULT} from "./CommonConstants";
import React from "react";


export const alphabeticGroupFilter = (toFilterList) => {
    let orderedList = [];
    for (let index in ALPHABET_LIST_LETTERS) {
        let letter = ALPHABET_LIST_LETTERS[index];
        orderedList.push({startingChar: letter, data: []});
        orderedList[index].data = toFilterList.filter((item) => {
            if (item && item.name) {
                return item.name.toLowerCase().startsWith(letter.toLowerCase());
            }
        })

    }
    return orderedList;
};


export const handleSearch = (input, listWhereToSearch, setState) => {
    let searchedList = [];
    for (let item of listWhereToSearch) {
        if ((item.name.toLowerCase().includes(input.toLowerCase()))
        ) {
            searchedList.push(item);
        }
    }
    if (searchedList.length === 0) {
        searchedList.push(NO_SEARCH_RESULT)
    }
    setState(searchedList)
};


