import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

import PaperDraggable from "./PaperDraggable";
import PaperDraggableSmall from "./PaperDraggableSmall";


class CommonDialog extends Component {

    renderDefaultActions = () => {
        let {
            handleClose,
            leftButtonText,
            rightButtonText,
            leftButtonOnClick,
            rightButtonOnClick,
            leftButtonColor,
            leftButtonVariant,
            leftStartIcon,
            rightButtonColor,
            rightButtonVariant,
            rightStartIcon,
            customStyle
        } = this.props;
        return <DialogActions >
            <Button
                style={customStyle && customStyle.leftButton ? customStyle.leftButton : undefined}
                startIcon={leftStartIcon ? leftStartIcon : undefined}
                onClick={leftButtonOnClick ? leftButtonOnClick : handleClose}
                color={leftButtonColor || "secondary"}
                variant={leftButtonVariant || "text"}
            >
                {leftButtonText}
            </Button>
            <Button
                startIcon={rightStartIcon ? rightStartIcon : undefined}
                type="submit"
                variant={rightButtonVariant || "contained"}
                color={rightButtonColor || "primary"}
                onClick={rightButtonOnClick ? rightButtonOnClick : undefined}
            >
                {rightButtonText}
            </Button>
        </DialogActions>;
    };

    renderDefaultContent = () => {
        let {
            handleSubmit,
            rightButtonOnClick,
            content,
            customActions,
        } = this.props;
        return <form
            onSubmit={e => {
                if (!rightButtonOnClick) {
                    handleSubmit(e);
                }
            }}
        >
            <DialogContent
                style={{maxHeight: "70vh", paddingBottom: "20px"}}
            >
                {content}
            </DialogContent>
            {customActions ?
                <DialogActions>{customActions}</DialogActions>
                :
                this.renderDefaultActions()
            }
        </form>
    };

    render() {
        let {
            open,
            handleClose,
            title,
            customContent,
            customActions,
            paperSizeIsSmall
        } = this.props;
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={!paperSizeIsSmall ? PaperDraggable : PaperDraggableSmall}
                scroll='paper'
                id={"mainContainer"}
            >
                <DialogTitle id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                {customContent ?
                    <DialogContent>
                        {customContent}
                    </DialogContent>
                    : this.renderDefaultContent()
                }
                {customContent && customActions ?
                    <DialogActions>{customActions}</DialogActions>
                    : customContent &&
                    this.renderDefaultActions()
                }
            </Dialog>
        );
    }
}

export default CommonDialog;
