import React from "react";
import BaseComponent from "../interfaces/BaseComponent";
import {GlobalContext} from "../constants/CommonConstants";
import { BrowserRouter, Route } from "react-router-dom";
import {LOADING_SCREEN, LOGGED_IN_ROUTER, LOGGED_OUT_ROUTER} from "../constants/NavigationConstants";
import LoadingScreen from "../screens/Loading/LoadingScreen";
import LoggedOutRouter from "./LoggedOutRouter";
import LoggedInRouter from "./LoggedInRouter";

class BaseRouter extends BaseComponent{
    render(){
        console.debug("passo da baserouter")
        return(
            <BrowserRouter>
                <Route exact path={LOADING_SCREEN.PATH}>
                    <LoadingScreen />
                </Route>
                <Route path={LOGGED_OUT_ROUTER.PATH}>
                    <LoggedOutRouter />
                </Route>
                <Route path={LOGGED_IN_ROUTER.PATH}>
                    <LoggedInRouter />
                </Route>
            </BrowserRouter>
        )
    }
}
BaseRouter.contextType = GlobalContext;
export default BaseRouter
