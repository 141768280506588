import axios from "axios";
import {AUTH_TOKEN_ID, BASE_PATH} from "../CommonConstants";
import store from "../../redux/store";
import {setErrorTime} from "../../redux/actions/systemVariables";
import {ERROR_EXPECTATION_FAILED} from "./ApiConst";


const AUTHORIZATION_PREFIX = "Bearer ";

const RESPONSE_ERRROR_STATUS_TOKEN_ESPIRED = 412;

const BaseAxios = axios.create({
    baseURL: BASE_PATH
});

// 2. Log della richiesta nell api
BaseAxios.interceptors.request.use(config => {
    console.debug("REQUEST", config.data, config);
    config.headers["Authorization"] =
        AUTHORIZATION_PREFIX + sessionStorage.getItem(AUTH_TOKEN_ID);
    return config;
});

// 3. Log della risposta dall api
BaseAxios.interceptors.response.use(
    response => {
        console.debug("RESPONSE", response);
        if (response && response.headers) {
            let newToken = response.headers["authorization"];
            // console.debug("RESPONSE oldToken,newToken ",sessionStorage.getItem(AUTH_TOKEN_ID),"\r\n", newToken);
            sessionStorage.setItem(AUTH_TOKEN_ID, newToken);
        }
        return response;
    },
    error => {
        console.error("ERROR_RESPONSE", error, error.response);
        if (error &&
            error.response && (error.response.status === RESPONSE_ERRROR_STATUS_TOKEN_ESPIRED || error.response.status === ERROR_EXPECTATION_FAILED)) {
            console.error("ERROR_RESPONSE STATUS IS: ", error.response.status, "DEVO FARE LOGOUT");
            store.dispatch(setErrorTime(null,true));
        }
        return Promise.reject(error);
    }
);

export default BaseAxios;

