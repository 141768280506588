import React from 'react';
import InterfaceScreen from "@bit/tech4care.basiccomponents.interface-screen";
import DeniedAccessMobile from "../components/Mobile/DeniedAccessMobile";
import DeniedAccessDesktop from "../components/Desktop/DeniedAccessDesktop";
import LoadingSystem from "../components/Loading/LoadingSystem";
import {FormattedMessage} from "react-intl";
import {GlobalContext,} from "../constants/CommonConstants";
import {LOGGED_IN_ROUTER} from "../constants/NavigationConstants";
import {TOP_BAR_MENU} from "../constants/NavigationHelper";





class BaseScreen extends InterfaceScreen {
    deniedAccessMobile = <DeniedAccessMobile/>;
    deniedAccessDesktop = <DeniedAccessDesktop/>;
    loadingSystem = <LoadingSystem loading={this.loading} setLoading={this.setLoading}/>
    snackBar = {
        sb: {
            vertical: "top",
            horizontal: "right",
            open: false,
            autoHideDuration: 30000,
            message: "No message"
        },
        sendToDevMessage: <FormattedMessage
            id={"errorHandler.sendErrorToDevs"}/>,
        sendToDevMessageSuccess: <FormattedMessage id={"errorHandler.sendErrorToDevs.success"}/>,
    };

    state = {
        // menuPagePath: LOGGED_IN_ROUTER.PATH + LOGGED_IN_ROUTER_MENU_PAGE.PATH,//Per la mobile mode
        topbarMenu: TOP_BAR_MENU,
        customStyle: {
            sidebar: {
                menuTitleLogo: {
                    backgroundColor: "white",
                    borderRight: "1px solid var(--border-color)"
                }
            },
            topbar: {
                pageContainer: {font: "var(--text-font-h6)"},
                sidebarMobile: {
                    menuTitleLogo: {
                        backgroundColor: "white",
                        borderRight: "1px solid var(--border-color)"
                    }
                },
            }


        }
    };

    render() {

        return <GlobalContext.Consumer>{
            globalContext => {
                return this.render2(globalContext)
            }
        }</GlobalContext.Consumer>
    }

}

export default BaseScreen;