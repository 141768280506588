import style from "../../../assets/styles/PatientInfo.module.css";
import React from "react";
import {tableStyle} from "../TableSettingsCommon";
import {textColor} from "../../../assets/theme/ThemeConstants";


export const getPatientRehabPlanColumns = (intl) => {
    return ([
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.level"})
            ,
            field: "value1",
            ...tableStyle(true,true),
            render: rowData => {
                return <div id={"avatarLevelNameContainer"} className={style.avatarLevelNameContainer}><img
                    className={style.avatar}
                    src={"data:image/png;base64," + rowData.avatar}/><label>{rowData.value1}</label></div>
            }
        },
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.status"})
            ,
            field: "value2",
            ...tableStyle(false,true),
        },
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.repetition"})
            ,
            field: "value3",
            ...tableStyle(false,true),
        },
    ])

};

export const PatientRehabPlanOptions = (pageSizeOptions) => {
    return {
        emptyRowsWhenPaging: false,
        pageSizeOptions: pageSizeOptions,
        search: false,
        paging: false,
        searchFieldAlignment: "left",
        selection: false,
        sorting: false,
        toolbar: false,
        selectionProps: (rowData) => ({
            color: "primary",
            borderColor: "primary", marginLeft: "2vw"
        }),

        headerStyle: {
            fontWeight: "bold",
            textTransform: "uppercase",
            backgroundColor: "#FFFFFF",
            color: textColor,
            paddingLeft: "1vw",
            paddingRight: "0.5vw",
            marginTop: "auto",
            marginBottom: "auto",
        },
        rowStyle: {
            borderTop: "1px solid #E4E4E4",
            borderBottom: "1px solid #E4E4E4"
        },
        actionsColumnIndex: -1,
        showTitle: false,
        showSelectAllCheckbox: false,
        showTextRowsSelected: false,
    }
}