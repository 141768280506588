import {AUTH_TOKEN_ID} from "./CommonConstants";
import {Close, Settings} from "@material-ui/icons";
import React from "react";
import {FormattedMessage} from "react-intl";
import {LOGGED_IN_ROUTER, LOGGED_IN_ROUTER_PROFILE_PAGE} from "./NavigationConstants";

export const logOut = history => {
    sessionStorage.removeItem(AUTH_TOKEN_ID);
    history.push("/loggedOut");
};
export const profilePage = history => {
    history.push(LOGGED_IN_ROUTER.PATH+LOGGED_IN_ROUTER_PROFILE_PAGE.PATH);
};

export const TOP_BAR_MENU = (history) => {
    return [
        {
            label: <FormattedMessage id={"topbar.popover.settings"}/>,
            action: () => {
                profilePage(history)
            },
            icon: <Settings/>
        },
        {
            label: <FormattedMessage id={"topbar.popover.logout"}/>,
            action: () => {
                logOut(history)
            },
            icon: <Close/>

        }

    ]
};