import React from "react";
import {Redirect, Route, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PatientListScreen from "../screens/LoggedIn/PatientListScreen/PatientListScreen";
import {SET_ERROR_TIME} from "../redux/actionKeyConst";
import {API_GET_USER_INFO, ERROR_EXPECTATION_FAILED, ERROR_FORBIDDEN} from "../constants/ApiHelpers/ApiConst";
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../constants/CommonConstants";
import {LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN} from "../constants/NavigationConstants";
import {setErrorTime} from "../redux/actions/systemVariables";
import {handleMyInfo} from "../constants/ApiHelpers/CustomerServiceResponse";
import BaseAxios from "../constants/ApiHelpers/BaseAxios";
import {logOut} from "../constants/NavigationHelper";
import BaseComponent from "../interfaces/BaseComponent";

class LoggedInRouter extends BaseComponent {

    state = {personalData: null}

    async componentDidMount() {
        let {history, dispatch} = this.props;
        this.setLoading(true, "LoggedInRouter - componentDidMount");
        this.setListener(SET_ERROR_TIME, (actual, previous) => {
            if (actual === true && previous !== true) {
                dispatch(setErrorTime(null, false));
                logOut(history);
            }
        });
        let personalData;
        try {
            let res = await BaseAxios.request({
                url: API_GET_USER_INFO,
                method: "GET"
            });

            if (res && res.data) {
                personalData = handleMyInfo(res)
            }

        } catch (error) {
            if (
                error &&
                error.response &&
                (error.response.status === ERROR_FORBIDDEN ||
                    error.response.status === ERROR_EXPECTATION_FAILED)
            ) {
                this.setSnackbarOpen(
                    "top",
                    "right",
                    true,
                    2000,
                    <FormattedMessage id="snackbar.error.auth"/>,
                    "error",
                    error.response.data.uuid ? error.response.data.uuid : error.response.status + error.response.statusText
                );
                logOut(history);
            }
        }
        this.setState({personalData})
        this.setLoading(false, "LoggedInRouter - componentDidMount");
    }


    renderDesktop=()=> {
        let {match} = this.props;
        let {personalData} = this.state;

        if (this.loading || !personalData) {
            return null;
        }

        return (
            <>
                <Route exact path={match.path}>
                    <Redirect to={match.path + LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN.PATH}/>
                </Route>
                <Route path={match.path + LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN.PATH}>
                    <PatientListScreen logOut={logOut} personalData={personalData}/>
                </Route>
            </>
        );
    }
}

LoggedInRouter.contextType = GlobalContext;

function mapStateToProps(state) {
    return {
        [SET_ERROR_TIME]: state[SET_ERROR_TIME],

    };
}

export default withRouter(connect(mapStateToProps)(LoggedInRouter));
