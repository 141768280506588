import React from 'react';
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import style from "../../../assets/styles/PatientDetail.module.css"
import {PATIENT_DETAIL_TABS} from "../../../constants/CommonConstants";
import PatientInfo from "../../../components/PatientDetail/PatientInfo";
import BaseAxios from "../../../constants/ApiHelpers/BaseAxios";
import {
    API_EDIT_PATIENT,
    API_EDIT_PATIENT_EXERCISES,
    API_GET_CUSTOMER_INFO,
    API_GET_LEVEL_LIST,
    API_GET_PATIENT_EXERCISES
} from "../../../constants/ApiHelpers/ApiConst";
import {
    DtoAdder,
    fromAPItoComponentList4columns,
    fromAPItoComponentListKeyValue,
    fromAPItoStats
} from "../../../constants/DialogsDto/DtoUtils";
import {
    clinicalDataDto,
    doctorNotesDto,
    editPatient,
    patientDto,
    sessionSettingsGeneralDto,
    statsDataDto
} from "../../../constants/DialogsDto/PatientDto";
import PatientRehabPlan from "../../../components/PatientDetail/PatientRehabPlan";
import FourColumnEditableList from "../../../components/FourColumnEditableList";
import PatientStats from "../../../components/PatientDetail/PatientStats";
import PatientActivityLog from "../../../components/PatientDetail/PatientActivityLog";
import BaseComponent from "../../../interfaces/BaseComponent";
import CommonFormNb from "@bit/tech4care.basiccomponents.common-form-nb";
import Save from "@material-ui/icons/Save";

const translationKey = "patientList"

class PatientDetail extends BaseComponent {

    loading = true;
    state = {
        tabIndex: PATIENT_DETAIL_TABS.PATIENT_INFO,
        translation: {
            addNewDialog:
                {
                    title: <FormattedMessage id={translationKey + ".dialogTitle.addNew"}/>,
                    leftButtonText: <FormattedMessage
                        id={translationKey + ".commonDialog.button.close"}/>,
                    rightButtonText: <FormattedMessage
                        id={translationKey + ".commonDialog.button.save"}/>
                }
        },
        personalData: [],
        clinicalData: [],
        doctorNotes: [],
    };


    async componentDidMount() {

        this.setLoading(true, "PatientDetail - componentDidMount");
        await this.refreshPatientData();

    }

    refreshPatientData = async () => {

        this.setLoading(true, "PatientDetail - componentDidMount");
        let {intl, enableSecondaryTopbar} = this.props;

        let {patientId} = this.props.location.state;
        let personalDataDialog = null;
        let clinicalDataDialog = null;
        let doctorNotesDialog = null;
        let sessionSettingsGeneralDialog = null;
        let exercisesSettingsDialog = null;


        let personalData = null;
        let clinicalData = null;
        let doctorNotes = null;
        let sessionSettingsGeneral = null;
        let exercisesSettings = null;
        let statsData = null;
        let logData = null;
        let exerciseStats = null;

        let patient = {};
        let exercises = {};
        let levels = await BaseAxios.request({
            url: API_GET_LEVEL_LIST,
            method: "GET"
        });

        let translationKey = "patientList.commonDialog.fields.";
        console.debug("[PatientInfo] refreshPatientData - patient", patient, doctorNotes,);

        // 0. Prendo l'id del paziente dalle props, chiamo l'API che mi ritorna tutti i dati
        let response = await BaseAxios.request({
            url: API_GET_CUSTOMER_INFO + patientId,
            method: "GET"
        });
        patient = response.data;


        let responseEx = await BaseAxios.request({
            url: API_GET_PATIENT_EXERCISES + patientId,
            method: "GET"
        });
        exercises = responseEx.data;

        // 0. Faccio comparire il secondo livello della TopBar
        enableSecondaryTopbar(true, patient.pdPatientDto.name, patient.pdPatientDto.surname);

        // 1. Creo la struttura dati per la modale per editare
        personalDataDialog = DtoAdder(patient.pdPatientDto, patientDto);
        clinicalDataDialog = DtoAdder(patient.pdPatientDto, clinicalDataDto);
        doctorNotesDialog = DtoAdder(patient.pdPatientDto, doctorNotesDto);
        sessionSettingsGeneralDialog = DtoAdder(patient.pdPatientDto, sessionSettingsGeneralDto);
        exercisesSettingsDialog = fromAPItoComponentList4columns(levels.data, exercises, true,translationKey);

        // 2. Creo la struttura dati per il componente InformationListCard
        personalData = fromAPItoComponentListKeyValue(patient.pdPatientDto, patientDto, translationKey, intl.formatMessage({
            id: translationKey + "age"
        }));
        clinicalData = fromAPItoComponentListKeyValue(patient.pdPatientDto, clinicalDataDto, translationKey);
        doctorNotes = fromAPItoComponentListKeyValue(patient.pdPatientDto, doctorNotesDto, translationKey);
        sessionSettingsGeneral = fromAPItoComponentListKeyValue(patient.pdPatientDto, sessionSettingsGeneralDto, translationKey);
        exercisesSettings = fromAPItoComponentList4columns(levels.data, exercises, false,translationKey);
        statsData = fromAPItoComponentListKeyValue(patient.stats, statsDataDto, translationKey);
        if (patient && patient.stats && patient.stats.listGameSessionDto) {
            logData = patient.stats.listGameSessionDto;
        }
        if (patient && patient.stats && patient.stats.listLevelDto) {

            exerciseStats = fromAPItoStats(patient.stats.listLevelDto);
        }

        console.debug("[PatientInfo] refreshPatientData - patient", exercisesSettings);


        this.setState({
            loading: false,
            patient: patient,
            personalData,
            clinicalData,
            doctorNotes,
            statsData,
            logData,
            exerciseStats,
            sessionSettingsGeneral,
            exercisesSettings,
            personalDataDialog,
            clinicalDataDialog,
            doctorNotesDialog,
            sessionSettingsGeneralDialog,
            exercisesSettingsDialog
        }, () => {
            this.setLoading(false, "PatientDetail - componentDidMount");
        });
    };

    handleSubmitEditExercises = async (e, globalContext, toggleDialog) => {
        let {intl} = this.props;
        let {patient, exercisesSettingsDialog} = this.state;

        let {resetDialogState, showDialog} = globalContext;

        try {
            this.setLoading(true, "PatientDetail - handleSubmitEditExercises");
            e.preventDefault();
            showDialog(false)
            // 1. Creo l'input prendendo tutti i value dalla struttura dati compilata tramite il Dialog
            let input = [];
            // 2. Riporto i dati che non vengono cambiati e devono essere mandati uguali a come vengono ricevuti
            for (let indexEx in exercisesSettingsDialog) {
                let exercise = exercisesSettingsDialog[indexEx];
                if (exercise.enabled) {
                    input.push({
                        levelId: exercise.levelId,
                        repetitions: exercise.value2
                    });
                }
            }


            // 3. Aggiorno i dati via API
            await BaseAxios.request({
                url: API_EDIT_PATIENT_EXERCISES + patient.pdPatientDto.customerId,
                method: "PUT",
                data: input
            });

            await BaseAxios.request({
                url: API_GET_PATIENT_EXERCISES + patient.pdPatientDto.customerId,
                method: "GET"
            });
            resetDialogState()
        } catch (error) {
            console.error("Errore edit esercizi", error.response);
            this.setSnackbarOpen(
                "top",
                "right",
                true,
                2000,
                intl.formatMessage({
                    id: "patientDetail.edit.error"
                }),
                "error"
            );
        } finally {
            this.setLoading(false, "PatientDetail - handleSubmitEditExercises");

            // 4. Refresho i dati della pagina
            await this.refreshPatientData()

            // 5. Chiudo la dialog
            // toggleDialog()
        }
    }

    handleSubmitEdit = async (e, globalContext, toggleDialog) => {
        let {intl} = this.props;
        let {patient} = this.state;

        let {resetDialogState, showDialog} = globalContext;

        try {
            this.setLoading(true);
            e.preventDefault();
            showDialog(false)
            // 1. Creo l'input prendendo tutti i value dalla struttura dati compilata tramite il Dialog
            let input = editPatient;

            // 2. Riporto i dati che non vengono cambiati e devono essere mandati uguali a come vengono ricevuti
            for (let index in patient.pdPatientDto) {
                if (index !== "tableData") {
                    input[index] = patient.pdPatientDto[index];
                }
            }


            for (let field in editPatient) {
                if (e.target[field] && e.target[field].value) {
                    input[field] = e.target[field].value;
                }
            }


            // 3. Aggiorno i dati via API
            await BaseAxios.request({
                url: API_EDIT_PATIENT + patient.pdPatientDto.customerId,
                method: "PUT",
                data: input
            });

            resetDialogState()
        } catch (error) {
            showDialog(true)
            console.error("Errore edit patient", error);
            this.setSnackbarOpen(
                "top",
                "right",
                true,
                2000,
                intl.formatMessage({
                    id: "patientDetail.edit.error"
                }),
                "error"
            );
        } finally {
            this.setLoading(false);

            // 4. Refresho i dati della pagina
            await this.refreshPatientData()

            // 5. Chiudo la dialog
            // toggleDialog()
        }
    };

    handleChangeTab = (event, tabIndex) => {
        this.setState({tabIndex});
    };

    editExercise = (event, checked, item, index, array, globalContext) => {
        let {refreshPropsDialog} = globalContext;
        let {exercisesSettingsDialog} = this.state;
        item.enabled = checked;
        if (item.enabled) {
            item.value2 = 1;
        }
        array[index] = item;
        refreshPropsDialog({
            content: <FourColumnEditableList
                contentValuesList={exercisesSettingsDialog}
                onChangeCheckBox={
                    (event, checked, item, index, array,) => {
                        this.editExercise(event, checked, item, index, array, globalContext)
                    }
                }
                translationKey={"patientList"}
                onChangeTextField={(event, item, index, array) => {

                    this.onChangeTextField(event, item, index, array, globalContext)
                }
                }
            />
        })
        this.setState({exercisesSettingsDialog: array})

    }

    onChangeTextField = (event, item, index, array, globalContext) => {
        let {refreshPropsDialog} = globalContext;
        let {exercisesSettingsDialog} = this.state;
        item.value2 = event.target.value;
        array[index] = item;
        refreshPropsDialog({
            content: <FourColumnEditableList
                contentValuesList={exercisesSettingsDialog}
                onChangeCheckBox={
                    (event, checked, item, index, array,) => {
                        this.editExercise(event, checked, item, index, array, globalContext)
                    }
                }
                translationKey={"patientList"}
                onChangeTextField={(event, item, index, array) => {

                    this.onChangeTextField(event, item, index, array, globalContext)
                }
                }
            />
        })
        this.setState({exercisesSettingsDialog: array})
    }

    renderTabContent = (tabIndex, globalContext) => {
        let {
            patient,
            personalData,
            clinicalData,
            doctorNotes, statsData,
            logData,
            exerciseStats,
            sessionSettingsGeneral,
            personalDataDialog,
            clinicalDataDialog,
            doctorNotesDialog,
            sessionSettingsGeneralDialog,
            exercisesSettings,
            exercisesSettingsDialog
        } = this.state;

        console.debug("[PatientDetail] renderTabContent: ", patient)
        switch (tabIndex) {
            case PATIENT_DETAIL_TABS.PATIENT_INFO:

                return <PatientInfo
                    patient={patient}
                    refreshPatientData={this.refreshPatientData}
                    renderAddNewDialog={this.renderAddNewDialog}
                    personalData={personalData}
                    clinicalData={clinicalData}
                    doctorNotes={doctorNotes}

                    personalDataDialog={personalDataDialog}
                    clinicalDataDialog={clinicalDataDialog}
                    doctorNotesDialog={doctorNotesDialog}

                    handleSubmitEdit={this.handleSubmitEdit}
                />;
            case PATIENT_DETAIL_TABS.REHAB_PLAN:
                return <PatientRehabPlan
                    patient={patient}
                    refreshPatientData={this.refreshPatientData}
                    renderAddNewDialog={this.renderAddNewDialog}
                    sessionSettingsGeneral={sessionSettingsGeneral}
                    exercisesSettings={exercisesSettings}
                    doctorNotes={doctorNotes}

                    sessionSettingsGeneralDialog={sessionSettingsGeneralDialog}
                    exercisesSettingsDialog={
                        <FourColumnEditableList
                            contentValuesList={exercisesSettingsDialog}
                            onChangeCheckBox={
                                (event, checked, item, index, array,) => {
                                    this.editExercise(event, checked, item, index, array, globalContext)
                                }
                            }
                            translationKey={"patientList"}
                            onChangeTextField={(event, item, index, array) => {

                                this.onChangeTextField(event, item, index, array, globalContext)
                            }
                            }
                        />}
                    doctorNotesDialog={doctorNotesDialog}
                    handleSubmitEditExercises={this.handleSubmitEditExercises}
                    handleSubmitEdit={this.handleSubmitEdit}
                />;
            case PATIENT_DETAIL_TABS.STATS:
                return <PatientStats statsData={statsData} exerciseStats={exerciseStats}
                                     refreshPatientData={this.refreshPatientData}
                />;
            case PATIENT_DETAIL_TABS.ACTIVITY_LOG:
                return <PatientActivityLog
                    refreshPatientData={this.refreshPatientData}
                    logData={logData} sessionDone={patient.stats.sessionDone}
                    sessionProgrammed={patient.pdPatientDto.sessionProgrammed}
                />;
            default:
                return "";
        }
    }


    renderAddNewDialog = (globalContext, isMobile, structDialog, handleSubmitEdit, exercisesSettingsDialog) => {
        let {translation} = this.state;
        let {setDialogState, resetDialogState} = globalContext;
        let dialogStruct = [];

        // 0. Spread operator della struttura dati per la Dialog, perché altrimenti i campi rimarrebbero popolati perché passati per riferimento e non per valore
        if (!exercisesSettingsDialog) {
            for (let field of structDialog) {
                dialogStruct.push({...field});
            }
        }
        // 1. la struttura mi arriva già popolata structDialog
        // 2. Apro la Dialog passando tutte le props necessarie
        setDialogState({
            dialogIsOpen: true,
            handleClose: () => {
                resetDialogState();
                this.forceUpdate();
            },
            title: translation.addNewDialog.title,
            content:
                exercisesSettingsDialog || <CommonFormNb
                    singleColumn={isMobile}
                    dialogComponents={dialogStruct}
                />
            ,
            leftButtonText: translation.addNewDialog.leftButtonText,
            rightButtonText: translation.addNewDialog.rightButtonText,
            rightStartIcon: <Save/>,
            handleSubmit:
                async (event) => {
                    event.preventDefault();
                    await handleSubmitEdit(event, globalContext);
                },
        });
    };

    renderDesktop = (globalContext) => {
        let {tabIndex, patient} = this.state;

        if (!patient) {
            return null;
        }

        console.debug("[PatientDetail] render -  arrivo qui", patient);
        return (
            <div id={"mainContainer"} className={style.mainContainer}>
                {/*<div id={"titleContainer"} className={style.titleContainer}><label id={"title"} className={style.title}><FormattedMessage*/}
                {/*    id={"patientDetail.title"}/> </label></div>*/ //TODO serve veramente?
                }
                <div id={"patientNameAvatarContainer"} className={style.patientNameAvatarContainer}>
                    <div id={"avatarContainer"} className={style.avatarContainer}>
                        <svg
                            width="40"
                            height="40"
                            data-jdenticon-value={patient.pdPatientDto.name}
                            viewBox="0 0 40 40"
                            preserveAspectRatio="xMidYMid meet"
                        />
                    </div>
                    <label id={"patientName"}
                           className={style.patientName}>{patient.pdPatientDto.name + " " + patient.pdPatientDto.surname}</label>
                </div>
                <div id={"tabsContainer"} className={style.tabsContainer}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                    >
                        <Tab
                            label={<FormattedMessage id="patientDetail.tabTitle.patientInfo"/>}
                            value={PATIENT_DETAIL_TABS.PATIENT_INFO}
                        />
                        <Tab
                            label={
                                <FormattedMessage id="patientDetail.tabTitle.rehabPlan"/>
                            }
                            value={PATIENT_DETAIL_TABS.REHAB_PLAN}
                        />
                        <Tab
                            label={<FormattedMessage id="patientDetail.tabTitle.stats"/>}

                            value={PATIENT_DETAIL_TABS.STATS}
                        />
                        <Tab
                            label={<FormattedMessage id="patientDetail.tabTitle.activityLog"/>}

                            value={PATIENT_DETAIL_TABS.ACTIVITY_LOG}
                        />
                    </Tabs>
                </div>
                <div id={"tabContentContainer"} className={style.tabContentContainer}>
                    {this.renderTabContent(tabIndex, globalContext)}
                </div>
            </div>
        );
    }
}

export default injectIntl(withRouter(PatientDetail));
