import React, {Component} from 'react';
import {Breakpoint} from "react-socks";
import LoadingMobile from "./LoadingMobile";
import style from "../../assets/theme/variables.module.css";
import {LinearProgress} from "@material-ui/core";


class LoadingSystem extends Component {
    render() {
        let {loading, setLoading} = this.props;
        return (
            <div>
                <Breakpoint small down>
                    <LoadingMobile loading={loading} setLoading={setLoading}/>
                </Breakpoint>
                <Breakpoint medium up
                >
                    <div className={style.loadingModal}>

                        <LinearProgress/>
                    </div>
                </Breakpoint>
            </div>
        );
    }
}

export default LoadingSystem;