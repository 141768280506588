import React, {Component} from "react";
import {Provider} from "react-redux";
import {IntlProvider} from "react-intl";
import BaseRouter from "./routing/BaseRouter";
import en from "./translations/en.json";
import it from "./translations/it.json";
import store from "./redux/store";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {BASE_THEME_CUSTOMIZATION} from "./assets/theme/ThemeConstants";
import {BreakpointProvider} from "react-socks"
import {IonApp} from "@ionic/react"

import {GlobalContext} from "./constants/CommonConstants";
import packageJson from '../package.json';
import CacheBuster from "./CacheBuster";
import CommonDialog from "./components/Dialogs/CommonDialog"
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

global.appVersion = packageJson.version;

const customTheme = createMuiTheme(BASE_THEME_CUSTOMIZATION);

const messages = {
    en: en,
    it: it
};


if (process.env.NODE_ENV === "production") {
    console.info = () => {
    };
    console.debug = () => {
    };
    console.log = () => {
    };
    console.group = () => {
    };
    console.groupEnd = () => {
    };
    console.track = {
        trackEvent: () => {
        }
    };
}


class App extends Component {
    state = {
        language: "it",
        gb: {},
        dialogProps: {dialogIsOpen: false}
    };


    totalRefresh = () => {
        this.forceUpdate()
    };


    resetDialogState = () => {
        this.setState({dialogProps: {dialogIsOpen: false}})
    };

    showDialog = (state, callback) => {
        this.setState(p => {
            p.dialogProps.dialogIsOpen = state;
            return p;
        }, () => {
            if (callback) {
                callback();
            }
        })
    };

    refreshPropsDialog = (props) => {
        this.setState({
            dialogProps: {
                ...this.state.dialogProps,
                ...props
            }
        })
    };

    setDialogState = ({
                          dialogIsOpen, rightButtonColor, customStyle,
                          leftButtonColor, leftButtonVariant, rightButtonVariant,
                          handleClose, paperSizeIsSmall, handleSubmit, leftButtonText,
                          rightButtonText, leftButtonOnClick, rightButtonOnClick, title, content,
                          customContent, customActions, rightStartIcon, leftStartIcon
                      }) => {
        this.setState({
            dialogProps: {
                dialogIsOpen,
                handleClose,
                handleSubmit,
                leftButtonText,
                rightButtonText,
                leftButtonOnClick,
                rightButtonOnClick,
                title,
                content,
                customContent,
                customActions,
                rightStartIcon,
                leftStartIcon,
                rightButtonColor,
                leftButtonColor,
                paperSizeIsSmall,
                rightButtonVariant,
                leftButtonVariant,
                customStyle
            }
        })
    };


    getLanguage = () => {
        return this.state.language;
    };
    setLanguage = language => {
        this.setState({language});
    };

    setGlobalVariable = (key, value) => {
        console.log("CHeck the change state: ", key, value, {gb: {[key]: value}});
        this.setState({gb: {[key]: value}});
    };

    getGlobalVariable = key => {
        console.log("CHeck the change state: GET ", key, this.state.gb);
        return this.state.gb[key];
    };


    render() {
        let {
            dialogProps: {
                dialogIsOpen, handleClose, handleSubmit,
                leftButtonText, rightButtonColor, customStyle,
                leftButtonColor, rightButtonVariant, leftButtonVariant, rightButtonText, paperSizeIsSmall, leftButtonOnClick, rightButtonOnClick, title, content, customContent, customActions, rightStartIcon, leftStartIcon
            }, gb
        } = this.state;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    }

                    return (
                        <IntlProvider
                            locale={this.getLanguage()}
                            messages={messages[this.getLanguage()]}
                        >
                            <Provider store={store}>
                                <ThemeProvider theme={customTheme}>
                                    <BreakpointProvider>
                                        <GlobalContext.Provider
                                            value={{
                                                setLanguage: this.setLanguage,
                                                getLanguage: this.getLanguage,
                                                setGlobalVariable: this.setGlobalVariable,
                                                getGlobalVariable: this.getGlobalVariable,
                                                setDialogState: this.setDialogState,
                                                resetDialogState: this.resetDialogState,
                                                showDialog: this.showDialog,
                                                totalRefresh: this.totalRefresh,
                                                refreshPropsDialog:this.refreshPropsDialog,
                                                gb
                                            }}
                                        >
                                            <IonApp>
                                                <BaseRouter/>
                                            </IonApp>
                                            <CommonDialog
                                                open={dialogIsOpen}
                                                handleClose={handleClose}
                                                handleSubmit={handleSubmit}
                                                leftButtonText={leftButtonText}
                                                rightButtonText={rightButtonText}
                                                leftButtonOnClick={leftButtonOnClick}
                                                rightButtonOnClick={rightButtonOnClick}
                                                title={title}
                                                content={content}
                                                customContent={customContent}
                                                customActions={customActions}
                                                rightStartIcon={rightStartIcon}
                                                leftStartIcon={leftStartIcon}
                                                paperSizeIsSmall={paperSizeIsSmall}
                                                rightButtonVariant={rightButtonVariant}
                                                leftButtonVariant={leftButtonVariant}
                                                rightButtonColor={rightButtonColor}
                                                leftButtonColor={leftButtonColor}
                                                customStyle={customStyle}
                                            />
                                        </GlobalContext.Provider>
                                    </BreakpointProvider>
                                </ThemeProvider>
                            </Provider>
                        </IntlProvider>);
                }}
            </CacheBuster>
        );
    }
}

export default App;
