import React from "react";

const initialState = {
    loading: null, success: null, error: null
};

export default function (state = initialState, action) {
    console.debug("ACTION IS ",action);
    if (action.type.startsWith("REQUEST")) {
        return {
            ...state,
            loading: true,
            success: null,
            error: null,
            ...action
        };
    } else if (action.type.startsWith("SUCCESS")) {
        return {
            ...state,
            loading: false,
            success: true,
            error: null,
            ...action
        };
    } else if (action.type.startsWith("ERROR")) {
        return {
            ...state,
            loading: false,
            success: false,
            error: action.error,
            ...action
        };
    } else {
        return state;
    }
}
