import React from "react";
import InterfaceComponent from "@bit/tech4care.basiccomponents.interface-component"
import {FormattedMessage} from "react-intl";
import DeniedAccessMobile from "../components/Mobile/DeniedAccessMobile";
import DeniedAccessDesktop from "../components/Desktop/DeniedAccessDesktop";
import LoadingSystem from "../components/Loading/LoadingSystem";
import {GlobalContext} from "../constants/CommonConstants";


class BaseComponent extends InterfaceComponent {
    deniedAccessMobile = <DeniedAccessMobile/>;
    deniedAccessDesktop = <DeniedAccessDesktop/>;
    loadingSystem = <LoadingSystem loading={this.loading} setLoading={this.setLoading}/>
    snackBar = {
        sb: {
            vertical: "top",
            horizontal: "right",
            open: false,
            autoHideDuration: 30000,
            message: "No message"
        },
        sendToDevMessage: <FormattedMessage
            id={"errorHandler.sendErrorToDevs"}/>,
        sendToDevMessageSuccess: <FormattedMessage id={"errorHandler.sendErrorToDevs.success"}/>,
    };


    renderMobile = (globalContext) => {
        return this.renderDesktop(globalContext);
    };


    render() {

        return <GlobalContext.Consumer>{
            globalContext => {
                return this.render2(globalContext)
            }
        }</GlobalContext.Consumer>
    }
}

export default BaseComponent;

