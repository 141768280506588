import {
  ERROR_FORBIDDEN,
  ERROR_UNAUTH
} from "../constants/ApiHelpers/ApiConst";
import BaseAxios from "../constants/ApiHelpers/BaseAxios";

/**
 * @class Action
 *
 * Perform actions
 */
export default class Action {
  /**
   * @param  {Function} dispatch - Redux dispatch function to trigger an action
   * @param  {string} onSuccessReturnField - the field which is going to be in the success reducer
   */
  constructor(dispatch, onSuccessReturnField) {
    this.dispatch = dispatch;
    if (onSuccessReturnField) {
      this.onSuccessReturnField = onSuccessReturnField;
      this.onSuccessLoadingField = "loading_" + onSuccessReturnField;
    } else {
      this.onSuccessReturnField = null;
    }
    this.requestType = "REQUEST";
    this.successType = "SUCCESS";
    this.errorType = "ERROR";
  }

  success = (data = null) => {
    this.dispatch({
      type: this.successType,
      [this.onSuccessLoadingField]: false,
      [this.onSuccessReturnField]: data
    });
  };

  error = error => {
    this.dispatch({
      type: this.errorType,
      error,
      [this.onSuccessLoadingField]: false,
      [this.onSuccessReturnField]: null
    });
  };

  callApi = async (input, handler,params) => {
    this.dispatch({
      type: this.requestType,
      [this.onSuccessLoadingField]: true,
      [this.onSuccessReturnField]: null
    });
    try {
      const res = await BaseAxios(input);
      if (res && res.status < 400) {
        let temp = handler(res,params);
        this.success(temp);
        return temp;
      } else if (res && res.status === ERROR_FORBIDDEN) {
        return "Invalid permission";
      } else if (res && res.status === ERROR_UNAUTH) {
        return "Invalid Auth token";
      } else {
        throw res;
      }
    } catch (error) {
      console.error("Error calling api", error);
      this.error(error);
      throw error;
    }
  };
}
