import React from 'react';
import style from "../../assets/styles/PatientActivityLog.module.css";
import {FormattedMessage, injectIntl} from "react-intl";
import FilteredTable from "@bit/tech4care.basiccomponents.filtered-table";
import {Chip, createMuiTheme, ThemeProvider} from "@material-ui/core";
import {chipsTextColor} from "../../assets/theme/ThemeConstants";
import {tableOptions, tableThemeOverride} from "../../constants/TableColumns/TableSettingsCommon";
import {getPatientActivityLogColumns} from "../../constants/TableColumns/PatientActivityLog/PatientActivityLog";
import BaseComponent from "../../interfaces/BaseComponent";


const customTheme = createMuiTheme(tableThemeOverride());

class PatientActivityLog extends BaseComponent {

    componentDidMount() {
        let {refreshPatientData} = this.props;

        if (refreshPatientData) {
            refreshPatientData();

        }
    }

    renderDesktop = () => {
        let {logData, sessionDone, intl} = this.props;
        let columns = getPatientActivityLogColumns(intl);
        console.debug("[PatientActivityLog] render", logData);

        let pageSizeOptions = [];
        for (let pageSizeOptionItem in [5, 10, 20]) {
            if (logData.length > pageSizeOptionItem) {
                pageSizeOptions.push(pageSizeOptionItem);
            } else if (pageSizeOptions.length === 0) {
                pageSizeOptions.push(logData.length);
                break;
            } else {
                break;
            }
        }
        return (
            <ThemeProvider theme={customTheme}>
                <div id={"mainContainer"} className={style.mainContainer}>
                    <Chip
                        color={"primary"}
                        style={{
                            width: "200px",
                            borderRadius: "5px",
                            color: chipsTextColor,
                            marginBottom: "25px"
                        }}
                        label={<FormattedMessage id={"patientDetail.activityLog.sessions"}

                                                 values={{
                                                     done: sessionDone.includes("null") ? "0" : sessionDone

                                                 }}/>}/>
                    <FilteredTable
                        style={{minWidth: "1000px"}}
                        data={logData || []} columns={columns}
                        options={tableOptions()}
                    />
                </div>
            </ThemeProvider>
        );
    }
}

export default injectIntl(PatientActivityLog);
