import {FormattedMessage} from "react-intl";
import React from "react";
import moment from "moment";

export let DtoCleaner = () => {

};
export let DtoAdder = (data, struct) => {
    let tempData = struct;
    for (let index in struct) {
        let field = struct[index].id;
        if (data[field]) {
            tempData[index].value = data[field];
        } else {
            tempData[index].value = undefined;
        }

    }

    return tempData;
};

export let fromAPItoStats = (levels) => {

    let output = [];


    for (let level of levels) {
        let res = {
            levelId: null,
            levelImage: null,
            levelName: null,
            repetitions: 0,
            errors: 0,
            avgTime: null,
            bestTime: null,
            worstTime: null,
            lastTime: null
        };
        let lastTime = null;
        let totTime = null;
        res.levelName = level.name;
        res.levelImage = level.image;
        res.levelId = level.id;
        for (let event of level.gameEventDto) {
            res.repetitions += 1;
            let duration = moment(event.endTime).diff(moment(event.startTime));
            if (event.status === 1) {
                res.errors += 1
            }


            if (!lastTime || moment(lastTime).isBefore(moment(event.insertDate))) {
                lastTime = event.insertDate;
                res.lastTime = duration;
            }
            // console.debug("[DtoUtils] fromAPItoStats Average time "+level.id, duration);
            totTime += duration;

            if (!res.bestTime || moment(res.bestTime).isAfter(moment(duration))) {
                res.bestTime = duration;
            }

            if (!res.worstTime || moment(res.worstTime).isBefore(moment(duration))) {
                res.worstTime = duration;
            }


        }
        res.avgTime = (totTime / (level.gameEventDto.length));
        output.push(res);
    }
    // console.debug("[DtoUtils] fromAPItoStats", output);
    return output;
};


export let fromAPItoComponentListKeyValue = (data, struct, prefixTranslation, age) => {
    let tempData = [];
    for (let index in struct) {
        let field = struct[index].id;
        if (data && data[field]) {
            if (field === "birthDate") {
                tempData.push({
                    key: <FormattedMessage id={prefixTranslation + field}/>,
                    value: data[field] + " (" + age + ": " + moment().diff(data[field], "years") + ")"
                });
            } else {
                if (struct[index].toMap) {

                    tempData.push({
                        key: <FormattedMessage id={prefixTranslation + field}/>,
                        value: <FormattedMessage id={prefixTranslation + field + "." + data[field]}/>
                    });
                } else {
                    if (struct[index].formatDate) {

                        tempData.push({
                            key: <FormattedMessage id={prefixTranslation + field}/>,
                            value: moment(data[field]).format("DD/MM/YYYY")
                        });
                    } else {

                        tempData.push({
                            key: <FormattedMessage id={prefixTranslation + field}/>,
                            value: data[field]
                        });
                    }

                }
            }
        } else {
            tempData.push({key: <FormattedMessage id={prefixTranslation + field}/>, value: null});
        }

    }
    // console.debug("[PatientDetail] cleanStruct - ho pulito", tempData, data, struct);
    return tempData;
};

export let fromAPItoComponentList4columns = (levels, exercises, edit, translationKey) => {
        let tempData = [];
        let found = false;
        for (let indexLevel in levels) {
            let level = levels[indexLevel];
            let tempEx = null;
            // console.debug("[DtoUtils] level" + indexLevel, level);
            for (let indexExercise in exercises) {
                let exercise = exercises[indexExercise];
                // console.debug("[DtoUtils] exercise" + indexExercise, exercise, exercise.levelId === level.id);
                if (exercise.levelId === level.id && !found) {
                    found = true;
                    if (edit) {
                        tempEx = {
                            levelId: level.id,
                            avatar: "data:image/png;base64," + level.image,
                            value1: level.name,
                            value2: exercise.repetitions,
                            enabled: true
                        };
                    } else {
                        tempEx = {
                            avatar: "data:image/png;base64," + level.image,
                            value1: level.name,
                            value2: <FormattedMessage id={translationKey + "exercise.active"}/>,
                            value3: exercise.repetitions,
                            enabled: true
                        };

                    }
                    break;
                }
            }
            if (!found) {
                if (edit) {
                    tempEx = {
                        levelId: level.id,
                        avatar: "data:image/png;base64," + level.image,
                        value1: level.name,
                        value2: "",
                        enabled: false
                    };
                } else {
                    tempEx = {
                        avatar: "data:image/png;base64," + level.image,
                        value1: level.name,
                        value2: <FormattedMessage id={translationKey + "exercise.notActive"}/>,
                        value3: "",
                        enabled: false
                    };
                }
            }
            found = false;
            tempData.push(tempEx)


        }
        console.debug("[DtoUtils] - fromAPItoComponentList4columns", tempData)
        return tempData;
    }
;
