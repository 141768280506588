import React from "react";
import BaseComponent from "../interfaces/BaseComponent";
import {Route, withRouter} from "react-router-dom";
import LoginScreen from "../screens/LoggedOut/LoginScreen/LoginScreen";
import {
    LOGGED_OUT_ROUTER_FORGOT_PASSWORD_SCREEN,
    LOGGED_OUT_ROUTER_LOGIN_SCREEN,
    LOGGED_OUT_ROUTER_RESET_PASSWORD_SCREEN
} from "../constants/NavigationConstants";
import ForgotPasswordScreen from "../screens/LoggedOut/ForgotPasswordScreen/ForgotPasswordScreen";
import ResetPasswordScreen from "../screens/LoggedOut/ResetPasswordScreen/ResetPasswordScreen";

class LoggedOutRouter extends BaseComponent {
    render() {
        console.debug("[LoggedOutRouter] ho chiamato loggedOutRouter");
        return (
            <>
                <Route
                    exact
                    path={
                        this.props.match.path + LOGGED_OUT_ROUTER_LOGIN_SCREEN.PATH
                    }
                >
                    <LoginScreen/>
                </Route>
                {/*<Route
                    path={
                        this.props.match.path  + LOGGED_OUT_ROUTER_SIGNUP_SCREEN.PATH
                    }
                >
                    <SignUpScreen />
                </Route>*/}
                <Route
                    path={
                        this.props.match.path +
                        LOGGED_OUT_ROUTER_FORGOT_PASSWORD_SCREEN.PATH
                    }
                >
                    <ForgotPasswordScreen/>
                </Route>
                <Route
                    path={
                        this.props.match.path +
                        LOGGED_OUT_ROUTER_RESET_PASSWORD_SCREEN.PATH
                    }
                >
                    <ResetPasswordScreen/>
                </Route>
            </>
        );
    }

}

export default withRouter(LoggedOutRouter)
