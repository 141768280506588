import tableStyle from "../../../assets/styles/TableStyle.module.css";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import React from "react";

export const  getPatientActivityLogColumns = (intl) => {
    return ([
        {
            title: <div className={tableStyle.headerWithFilterDiv}>
                <div>{intl.formatMessage({id: "patientDetail.activityLog.fieldTable.sessionDate"})}</div>
            </div>,
            field: "insertDate",
            cellStyle: {
                paddingLeft: "20px",
            },
            headerStyle: {
                paddingLeft: "20px"
            },
            render:
                rowData => {
                    return (
                        <label>{moment(rowData.insertDate).format("YYYY-MM-DD")}</label>
                    )
                }
        },
        {
            title: <div className={tableStyle.headerWithFilterDiv}>
                <div> {intl.formatMessage({id: "patientDetail.activityLog.fieldTable.startingTime"})}</div>
            </div>,
            field: "startTime",
            sorting: false,
            render:
                rowData => {
                    if (!rowData.startTime) {
                        return <label><FormattedMessage id={"patientList.table.noData"}/> </label>
                    }
                    return (
                        <label>{moment(rowData.startTime).format("HH:mm")}</label>
                    )
                }
        },
        {
            title: <div className={tableStyle.headerWithFilterDiv}>
                <div> {intl.formatMessage({id: "patientDetail.activityLog.fieldTable.endingTime"})}</div>
            </div>,
            field:
                "endTime",
            render:
                rowData => {
                    if (!rowData.endTime) {
                        return <label><FormattedMessage id={"patientList.table.noData"}/> </label>
                    }
                    return (
                        <label>{moment(rowData.endTime).format("HH:mm")}</label>
                    )
                }
        }
        ,
        {
            title: <div className={tableStyle.headerWithFilterDiv}>
                <div> {intl.formatMessage({id: "patientDetail.activityLog.fieldTable.duration"})}</div>
            </div>,
            field: "duration",
            render:
                rowData => {
                    if (!rowData.duration) {
                        return <label><FormattedMessage id={"patientList.table.noData"}/> </label>
                    }

                    return (
                        <label>{rowData.duration + " "}<FormattedMessage
                            id={"patientDetail.activityLog.fieldTable.minutes"}/></label>
                    )
                }
        }
        ,

        {
            title: <div className={tableStyle.headerWithFilterDiv}>
                <div> {intl.formatMessage({id: "patientDetail.activityLog.fieldTable.done"})}</div>
            </div>,
            filter: {
                filterAction: (datas, filterSelectedValues) => {
                    let filteredData = [];
                    for (let data of datas) {

                        if (filterSelectedValues.includes(data.success)) {
                            filteredData.push(data);
                            break;
                        }

                    }
                    if (Array.isArray(filteredData) && filteredData.length > 0) {

                        return filteredData;
                    }
                    return datas;
                },
                filterPopupTitle: intl.formatMessage({id: "patientDetail.activityLog.fieldTable.done"}),
                allFilterValues: [0, 1],
                renderFilterValues: (value) => {
                    let tempValue = "";
                    if (value === 0) {
                        tempValue = "patientDetail.activityLog.fieldTable.done.no";

                    } else {
                        tempValue = "patientDetail.activityLog.fieldTable.done.yes";
                    }
                    return <FormattedMessage id={tempValue}/>;
                },
            },
            field: "success",
            render:
                rowData => {
                    if (rowData.success === null) {
                        return <label><FormattedMessage id={"patientList.table.noData"}/> </label>
                    }
                    let res = "";

                    if (rowData.success === 0) {
                        res = "patientDetail.activityLog.fieldTable.done.no";
                    } else {
                        res = "patientDetail.activityLog.fieldTable.done.yes";
                    }

                    return (
                        <label><FormattedMessage id={res}/></label>
                    )
                }
        }
        ,
        {
            title: <div className={tableStyle.headerWithFilterDiv}>
                <div> {intl.formatMessage({id: "patientDetail.activityLog.fieldTable.error"})}</div>
            </div>,
            field:
                "success",
            cellStyle:
                {},
            render:
                rowData => {
                    if (rowData.success === null) {
                        return <label><FormattedMessage id={"patientList.table.noData"}/> </label>
                    }
                    let res = "";

                    if (rowData.success === 0) {
                        res = "patientDetail.activityLog.fieldTable.error.network";
                    } else {
                        res = "patientDetail.activityLog.fieldTable.error.none";
                    }

                    return (
                        <label><FormattedMessage id={res}/></label>
                    )
                }
        }

    ])
        ;
};