export const API_SIGN_IN =
    "/api/v1/customer-service/signin";

export const API_FORGOT_PASSWORD =
    "/api/v1/customer-service/forgotPassword/";

export const API_RESET_PASSWORD =
    "/api/v1/customer-service/resetPassword";


export const API_GET_USER_INFO =
  "/api/v1/customer-service/myInfo";

export const API_GET_CUSTOMERS_INFO =
  "/api/v1/customer";
export const API_GET_CUSTOMER_INFO =
  "/api/v1/customer/";

export const API_CREATE_PATIENT=
    "/api/v1/customer-service/registerCustomer";

export const API_EDIT_PATIENT=
    "/api/v1/customer/";
export const API_DELETE_PATIENT=
    "/api/v1/customer/";
export const API_GET_LEVEL_LIST=
    "/api/v1/level";
export const API_GET_PATIENT_EXERCISES=
    "/api/v1/exercise/";
export const API_EDIT_PATIENT_EXERCISES=
    "/api/v1/exercise/";


export const ERROR_FORBIDDEN = 403;
export const ERROR_EXPECTATION_FAILED = 417;
export const ERROR_UNAUTH = 401;
