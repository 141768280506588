import React from "react";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import LoginPage from "@bit/tech4care.basiccomponents.login-page"
import AccountChip from "@bit/tech4care.basiccomponents.account-chip";

import BaseComponent from "../../../interfaces/BaseComponent";
import {API_GET_USER_INFO, API_SIGN_IN} from "../../../constants/ApiHelpers/ApiConst";
import {handleSignIn} from "../../../constants/ApiHelpers/AuthResponse";
import BaseAxios from "../../../constants/ApiHelpers/BaseAxios";
import {
    LOGGED_OUT_ROUTER,
    LOGGED_OUT_ROUTER_FORGOT_PASSWORD_SCREEN,
    LOGGED_OUT_ROUTER_TERMS_AND_CONDITIONS_SCREEN
} from "../../../constants/NavigationConstants";
import {logo} from "../../../assets/images/logos/logo";
import {handleMyInfo} from "../../../constants/ApiHelpers/CustomerServiceResponse";
import {AUTH_TOKEN_ID} from "../../../constants/CommonConstants";
import {tableThemeOverride} from "../../../constants/TableColumns/TableSettingsCommon";

class LoginScreen extends BaseComponent {


    renderAccountList = (accountsData, username, password, resetDialogState) => {
        let output = [];
        for (let index in accountsData) {
            let accountData = accountsData[index];
            output.push(
                <AccountChip
                    key={"accountContainer" + index}
                    id={"accountContainer" + index}
                    onClick={async () => {
                        await this.realLogin(accountData.id, username, password, resetDialogState)
                    }}
                    avatar={accountData.avatar}
                    avatarAlt={accountData.id}
                    title={accountData.groupId}
                    subtitle={accountData.roleId}
                />
            )
        }

        return output
    };

    realLogin = async (id, username, password, resetDialogState, fakeLogin) => {
        let secondSignin = await BaseAxios.request({
            url: API_SIGN_IN + "/" + id,
            method: "GET",
            auth: {
                username,
                password
            }
        });
        console.debug("CHeck secondSignin: ", secondSignin);
        handleSignIn(secondSignin);
        if (!fakeLogin) {
            this.props.history.push("/loggedIn/");
            this.setState({errorLogin: false});
            if (resetDialogState) {
                resetDialogState()
            }
        }
    };


    handleSubmit = async (e, globalContext) => {
        let {intl} = this.props;

        try {
            e.preventDefault();
            let username = e.target["username"].value;
            let password = e.target["password"].value;
            let {setDialogState, resetDialogState} = globalContext;

            this.setLoading(true, "LoginScreen - handleSubmit");
            let firstSignin = await BaseAxios.request({
                url: API_SIGN_IN,
                method: "GET",
                auth: {
                    username,
                    password
                }
            });

            console.debug("CHeck firstSignin: ", firstSignin, firstSignin.data[0].id);

            if (firstSignin.data.length === 1) {
                await this.realLogin(firstSignin.data[0].id, username, password)
            } else {
                // 0. Eseguo un login con il primo risultato dell'array, solo per prenderci nome e cognome
                await this.realLogin(firstSignin.data[0].id, username, password, null, true)
                let fakeLogin = await BaseAxios.request({
                    url: API_GET_USER_INFO,
                    method: "GET",
                });
                let personalData = handleMyInfo(fakeLogin);
                // 1. Ho ottenuto di dati personali, rimuovo il token in attesa del vero login
                sessionStorage.removeItem(AUTH_TOKEN_ID);
                setDialogState(
                    {
                        dialogIsOpen: true,
                        handleClose: () => {
                            resetDialogState()
                        },
                        handleSubmit: () => {
                        },
                        title: <div id={"titleContainer"} style={{display: "flex", flexDirection: "column"}}>
                            <label style={{font: "var(--text-font-h4)"}}><FormattedMessage
                                id={"login.welcomeBack.accountList"}
                                values={{user: personalData.name}}
                            /></label>
                            <label style={{font: "var(--text-font-h6)"}}><FormattedMessage
                                id={"login.welcomeBack.accountList.choseAccount"}
                            /></label>
                        </div>
                        ,
                        customContent: this.renderAccountList(firstSignin.data, username, password, resetDialogState),
                        customActions: <></>
                    }
                )
            }


        } catch (error) {
            console.error("Error during login: ", error.response);
            let tmpEr;
            if (error && error.response && error.response.data && error.response.data.uuid) {
                tmpEr = error.response.data.uuid;
            } else if (error && error.response && error.response.status && error.response.statusText) {
                tmpEr = error.response.status + error.response.statusText;
            }
            this.setSnackbarOpen(
                "top",
                "right",
                true,
                2000,
                intl.formatMessage({
                    id: "login.error"
                }),
                "error",
                tmpEr
            );
            this.setState({errorLogin: true});
        } finally {
            this.setLoading(false, "LoginScreen - handleSubmit");
        }
    };


    renderLoginPage = (globalContext) => {
        let {intl} = this.props;
        return (
            <LoginPage
                handleSubmit={async (e) => {
                    await this.handleSubmit(e, globalContext)
                }}
                labelList={
                    {
                        username: intl.formatMessage({id: "login.username.pl"}),
                        password: intl.formatMessage({id: "login.password.pl"}),
                        forgotPassword: intl.formatMessage({id: "login.forgotpassword"}),
                        loginError: intl.formatMessage({id: "login.error"}),
                        signIn: intl.formatMessage({id: "login.signin"}),
                        rememberMe: intl.formatMessage({id: "login.rememberme"}),
                        signUp: intl.formatMessage({id: "login.signup"}),
                        termsAndCondition: intl.formatMessage({id: "login.termsAndCondition"}),
                    }
                }
                signInButtonColor={"secondary"}
                enableSignUp={true}
                enableRememberMe={false}
                signUpHref={LOGGED_OUT_ROUTER.PATH}
                forgotPasswordHref={LOGGED_OUT_ROUTER.PATH + LOGGED_OUT_ROUTER_FORGOT_PASSWORD_SCREEN.PATH}
                termsAndConditionsHref={LOGGED_OUT_ROUTER.PATH + LOGGED_OUT_ROUTER_TERMS_AND_CONDITIONS_SCREEN.PATH}
                enableDarkLayer={false}
                commonThemeConstant={tableThemeOverride}
                logo={logo}
            />
        )
    };

    renderMobile = (globalContext) => {
        return this.renderLoginPage(globalContext)
    };

    renderDesktop = (globalContext) => {
        return this.renderLoginPage(globalContext)

    }

}

export default injectIntl(withRouter(LoginScreen));
