import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import ArrowBack from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import queryString from 'query-string'


import style from "../../../assets/styles/ForgotPassword.module.css"
import BaseAxios from "../../../constants/ApiHelpers/BaseAxios";
import {API_RESET_PASSWORD} from "../../../constants/ApiHelpers/ApiConst";
import {FUTURE_UPDATE, GlobalContext} from "../../../constants/CommonConstants";
import TextField from "@material-ui/core/TextField";

class ResetPasswordScreen extends Component {

    state = {
        errorPassword: false
    };

    componentDidMount() {
    }

    handleSubmit = async (e, globalContext) => {
        let {setLoading, setSnackbarOpen} = globalContext;
        let {intl} = this.props;
       
        try {
            e.preventDefault();
            setLoading(true);
            let password = e.target[0].value;
            let confirmPassword = e.target[2].value;
            let values = queryString.parse(this.props.location.search)
            if (password === confirmPassword) {
                let response = await BaseAxios.request({
                    url: API_RESET_PASSWORD,
                    method: "POST",
                    data: {
                        password,
                        confirmPassword,
                        customerIdToken: values.token
                    }
                });
                if(response.status===200){
                setSnackbarOpen(
                    "top",
                    "right",
                    true,
                    2000,
                    intl.formatMessage({
                        id: "forgotPassword.reset.success"
                    }),
                    "success"
                );}

            } else {
                setSnackbarOpen(
                    "top",
                    "right",
                    true,
                    2000,
                    intl.formatMessage({
                        id: "forgotPassword.reset.pw.wrong"
                    }),
                    "error"
                );
            }
        } catch (error) {
            console.error("Error during reset password: ", error);
            setSnackbarOpen(
                "top",
                "right",
                true,
                2000,
                intl.formatMessage({
                    id: "forgotPassword.error"
                }),
                "error"
            );
        } finally {
            setLoading(false);
        }
    };

    render() {
        let {intl, history} = this.props;
        let {errorPassword} = this.state;
        return (
            <GlobalContext.Consumer>
                {globalContext => {
                    return (
                        <div id={"mainContainer"} className={style.mainContainer} style={{"--first-color": "#DEDEDE"}}>
                            <div id={"formContainer"} className={style.formContainer}>
                                {FUTURE_UPDATE && <div id={"backArrowContainer"} className={style.backArrowContainer}>
                                    <IconButton onClick={() => {
                                        history.goBack();
                                    }}>
                                        <ArrowBack/>
                                    </IconButton>
                                </div>}
                                <div id={"descriptionContainer"} className={style.descriptionContainer}>
                                    <label id={"subtitle"} className={style.subtitle}><FormattedMessage
                                        id={"reset.welcome"}/> </label>

                                </div>
                                <form
                                    className={style.form}
                                    onSubmit={e => {
                                        this.handleSubmit(e, globalContext);
                                    }}
                                >
                                    <TextField
                                        autoFocus={true}
                                        error={errorPassword}
                                        // Se è presente un errore allora mostro il testo dell'errore
                                        helperText={
                                            errorPassword
                                                ? intl.formatMessage({
                                                    id: "reset.password.sg"
                                                })
                                                : null
                                        }
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="password"
                                        label={intl.formatMessage({id: "login.password.pl"})}
                                        name="password"
                                        type="password"
                                        autoComplete="password"
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="confirm-password"
                                        label={intl.formatMessage({id: "reset.confirmpassword.pl"})}
                                        type="confirm-password"
                                        type="password"
                                        id="confirm-password"
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={style.submit}
                                    >
                                        <FormattedMessage id="reset.change"/>
                                    </Button>
                                </form>
                            </div>
                        </div>)
                }}</GlobalContext.Consumer>
        );
    }
}

export default injectIntl(withRouter(ResetPasswordScreen));