import React from 'react';
import InformationListCard from "@bit/tech4care.basiccomponents.information-list-card";
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import style from "../../assets/styles/PatientInfo.module.css";
import InformationListTitleCard from "@bit/tech4care.basiccomponents.information-list-title-card";
import Grid from "@material-ui/core/Grid";
import BaseComponent from "../../interfaces/BaseComponent";
import {informationListCardStyle} from "../../assets/reactStyles/personalDataStyle";
import FilteredTable from "@bit/tech4care.basiccomponents.filtered-table";
import {
    getPatientRehabPlanColumns,
    PatientRehabPlanOptions
} from "../../constants/TableColumns/PatientRehabPlan/PatientRehabPlanColumns";

class PatientRehabPlan extends BaseComponent {

    componentDidMount() {
        let {refreshPatientData} = this.props;

        if (refreshPatientData) {
            refreshPatientData();

        }
    }

    renderDesktop = (globalContext) => {
        let {
            sessionSettingsGeneral, exercisesSettings,
            doctorNotes,
            sessionSettingsGeneralDialog,
            exercisesSettingsDialog,
            doctorNotesDialog,
            handleSubmitEdit,
            handleSubmitEditExercises,
            renderAddNewDialog, intl
        } = this.props;

        let pageSizeOptions = [];
        if (exercisesSettings) {
            for (let pageSizeOptionItem in [5, 10, 20]) {
                if (exercisesSettings.length > pageSizeOptionItem) {
                    pageSizeOptions.push(pageSizeOptionItem);
                } else if (pageSizeOptions.length === 0) {
                    pageSizeOptions.push(exercisesSettings.length);
                    break;
                } else {
                    break;
                }
            }
        }
        let columns = getPatientRehabPlanColumns(intl)

        return (
            <Grid container spacing={5}  justify={"flex-start"}>
                <Grid item xs={"auto"}>
                    <InformationListCard
                        customStyle={
                            informationListCardStyle(
                                {
                                    mainContainer: {height: "614px"},
                                    headerContainer: {
                                        height: "50px",
                                    },
                                    contentListContainer: {height: "490px",},
                                    rowContainer: {
                                        height: "60px"
                                    }
                                }
                            )
                        }
                        id={"sessionSettingsGeneral"}
                        headerComponent={<label className={style.cardsTitle}><FormattedMessage
                            id={"patientDetail.card.personalData.title"}/></label>}
                        contentValuesList={sessionSettingsGeneral}
                        footerComponent={<Button onClick={() => {
                            renderAddNewDialog(globalContext, false, sessionSettingsGeneralDialog, handleSubmitEdit)
                        }} startIcon={<Edit/>}
                                                 variant={"contained"}
                                                 color={"primary"}><FormattedMessage
                            id={"patientDetail.button.edit"}/></Button>}/>

                </Grid>

                <Grid item xs={"auto"}>
                    <InformationListCard
                        customStyle={
                            informationListCardStyle({
                                mainContainer: {height: "614px"},
                                headerContainer: {
                                    height: "182px",
                                },
                                contentContainer: {
                                    height: "358px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    paddingLeft: 0
                                },

                            })
                        }
                        headerComponent={<InformationListTitleCard
                            customStyle={{

                                mainContainer: {
                                    font: "var( --text-font-h5)",
                                    width: "460px", height: "137px", border: "0px",
                                    borderTopRightRadius: "5px",
                                    borderTopLeftRadius: "5px",
                                },
                                headerContainer: {

                                    height: "35px",
                                    paddingTop: "10px",
                                    paddingBottom: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                },
                                contentContainer: {
                                    height: "125px"
                                },
                                footerContainer: {
                                    // height: "30px"
                                    height: 0
                                },


                            }}
                            sectionTitle={<label className={style.cardsTitle}><FormattedMessage
                                id={"patientDetail.card.exercisesSettings.title"}/></label>}
                            footerContent={[
                                <FormattedMessage
                                    id={"patientDetail.card.exercisesSettings.footerContent.level"}/>,
                                <FormattedMessage
                                    id={"patientDetail.card.exercisesSettings.footerContent.state"}/>,
                                <FormattedMessage
                                    id={"patientDetail.card.exercisesSettings.footerContent.repGoal"}/>
                            ]}
                        />}
                        contentComponent={
                            <FilteredTable
                                columns={columns} data={exercisesSettings || []}
                                options={PatientRehabPlanOptions(pageSizeOptions)}
                            />
                        }
                        id={"exercisesSettings"}
                        contentValuesList={exercisesSettings}
                        footerComponent={<Button onClick={() => {
                            renderAddNewDialog(globalContext, false, null, handleSubmitEditExercises, exercisesSettingsDialog)
                        }} startIcon={<Edit/>}
                                                 variant={"contained"}
                                                 color={"primary"}><FormattedMessage
                            id={"patientDetail.button.edit"}/></Button>}/>
                </Grid>
                <Grid item xs={"auto"}>
                    <InformationListCard
                        customStyle={informationListCardStyle({
                            mainContainer: {height: "614px"},
                            headerContainer: {
                                height: "50px",
                            },
                            contentContainer: {
                                height: "490px",
                                padding: "10px 10px"
                            },

                        })}
                        id={"doctorNotes"}
                        headerComponent={<label className={style.cardsTitle}><FormattedMessage
                            id={"patientDetail.card.doctorNotes.title"}/></label>}
                        contentComponent={
                            <label
                                className={style.textArea}>{doctorNotes && doctorNotes[0] && doctorNotes[0].value}</label>
                        }

                        footerComponent={<Button onClick={() => {
                            renderAddNewDialog(globalContext, false, doctorNotesDialog, handleSubmitEdit)
                        }} startIcon={<Edit/>}
                                                 variant={"contained"}
                                                 color={"primary"}><FormattedMessage
                            id={"patientDetail.button.edit"}/></Button>}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default injectIntl(PatientRehabPlan);