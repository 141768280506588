import React from "react";
import { withRouter } from "react-router-dom";
import BaseComponent from "../../interfaces/BaseComponent";
import { AUTH_TOKEN_ID } from "../../constants/CommonConstants";
import {
	LOGGED_IN_ROUTER,
	LOGGED_OUT_ROUTER
} from "../../constants/NavigationConstants";

class LoadingScreen extends BaseComponent {
	componentDidMount() {
		let token = sessionStorage.getItem(AUTH_TOKEN_ID);
		console.debug("Token is ", token)
		if (token) {
			this.props.history.push(LOGGED_IN_ROUTER.PATH);
		} else {
			this.props.history.push(LOGGED_OUT_ROUTER.PATH);
		}
	}

	render() {
		return (
			<div>
				<p>Loading... TODO</p>
			</div>
		);
	}
}
export default withRouter(LoadingScreen);
