import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";

class DeniedAccessMobile extends Component {
    render() {
        return (
            <div id={"mainContainer"} style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "100vh"
            }}>
                <div id={"logoContainer"}></div>
                <div id={"descriptionContainer"}
                     style={{display: "flex", justifyContent: "center", textAlign: "center"}}><h4><FormattedMessage
                    id={"accessDenied.mobile.description"}/></h4></div>
            </div>
        );
    }
}

export default DeniedAccessMobile;
