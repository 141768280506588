import React from 'react';
import BaseAxios from "../constants/ApiHelpers/BaseAxios";
import InterfaceTableListPage from "@bit/tech4care.basiccomponents.interface-table-list-page";
import {COMMON_DELETE} from "../constants/ApiHelpers/CommonApi";
import {tableThemeOverride} from "../constants/TableColumns/TableSettingsCommon";
import DeniedAccessMobile from "../components/Mobile/DeniedAccessMobile";
import DeniedAccessDesktop from "../components/Desktop/DeniedAccessDesktop";
import LoadingSystem from "../components/Loading/LoadingSystem";
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../constants/CommonConstants";
import {alphabeticGroupFilter} from "../constants/CommonUtils";
import {DtoAdder} from "../constants/DialogsDto/DtoUtils";


class BaseTableListPage extends InterfaceTableListPage {

    translationTableListPage = (translationKey, intl, pageDescription) => {

        return {
            addNewDialog:
                {
                    title: <FormattedMessage id={translationKey + ".dialogTitle.addNew"}/>,
                    leftButtonText: <FormattedMessage
                        id={translationKey + ".commonDialog.button.close"}/>,
                    rightButtonText: <FormattedMessage
                        id={translationKey + ".commonDialog.button.save"}/>
                },
            deleteContent: {
                descriptionContainer: <FormattedMessage
                    id={translationKey + ".commonDialog.confirmDelete.description"}/>,
                hardDeleteDescription: <FormattedMessage
                    id={translationKey + ".commonDialog.confirmDelete.hardDeleteDescription"}/>,
                placeholder: intl.formatMessage({id: translationKey + ".commonDialog.confirmDelete.textField.placeholder"})
            },
            confirmDelete: {
                title: <FormattedMessage id={translationKey + ".commonDialog.confirmDelete.title"}/>,
                leftButtonText: <FormattedMessage
                    id={translationKey + ".commonDialog.confirmDelete.button.cancel"}/>,
                rightButtonText: <FormattedMessage
                    id={translationKey + ".commonDialog.confirmDelete.button.delete"}/>
            },
            deleteApi: {
                error: intl.formatMessage({
                    id: translationKey + ".delete.error"
                })
            },
            mobileFiltersDialog: {
                title: <FormattedMessage id={translationKey + ".filterMobile.label"}/>,
                leftButtonText: <FormattedMessage
                    id={translationKey + ".commonDialog.button.close"}/>,
                rightButtonText: <FormattedMessage
                    id={translationKey + ".commonDialog.button.apply"}/>
            },
            filterMobileContent: {
                errorMissingSplitaButtonData: <FormattedMessage
                    id={translationKey + ".label.splitButton.missingData"}/>,
                labelSplitButtonContainer: <FormattedMessage id={translationKey + ".label.splitButton"}/>
            },
            renderMobile: {
                filterButtonLabelContainer: <FormattedMessage id={translationKey + ".filterMobile.label"}/>,
                medicalOfficeLabelSearchContainer: <FormattedMessage id={translationKey + ".pageTitle"}/>,
                leftButtonText: <FormattedMessage
                    id={translationKey + ".table.field.delete"}/>,
                rightButtonText: <FormattedMessage
                    id={translationKey + ".table.field.view"}/>
            },
            createTableRecordComponent: {
                createNewButton: <FormattedMessage
                    id={translationKey + ".table.action.createNew"}/>
            },
            renderDesktop: {
                pageTitle: <FormattedMessage
                    id={translationKey + ".pageTitle"}/>,
                pageDescription: pageDescription ? <FormattedMessage
                    id={translationKey + ".pageDescription"}/> : false,
                errorMissingSplitaButtonData: <FormattedMessage
                    id={translationKey + ".label.splitButton.missingData"}/>,
                labelSplitButtonContainer: <FormattedMessage id={translationKey + ".label.splitButton"}/>
            },
            handleSubmit: {
                createError: intl.formatMessage({
                    id: translationKey + ".create.error"
                }),
                editError: intl.formatMessage({
                    id: translationKey + ".edit.error"
                })
            }

        }
    };
    deniedAccessMobile = <DeniedAccessMobile/>;
    deniedAccessDesktop = <DeniedAccessDesktop/>;
    loadingSystem = <LoadingSystem loading={this.loading} setLoading={this.setLoading}/>
    snackBar = {
        sb: {
            vertical: "top",
            horizontal: "right",
            open: false,
            autoHideDuration: 30000,
            message: "No message"
        },
        sendToDevMessage: <FormattedMessage
            id={"errorHandler.sendErrorToDevs"}/>,
        sendToDevMessageSuccess: <FormattedMessage id={"errorHandler.sendErrorToDevs.success"}/>,
    };


    render() {

        return <GlobalContext.Consumer>{
            globalContext => {
                return this.render2(globalContext)
            }
        }</GlobalContext.Consumer>
    }


    state = {
        //Metodo comune per eliminare dati tramite API
        deleteApiMethod: COMMON_DELETE,

        //Customizzazione grafica del tema
        commonThemeConstant: tableThemeOverride,

        //Props per la FilteredTable
        getTableColumns: () => {
        },
        tableOptions: () => {
        },
        tableLocalization: () => {
        },
        //Per rendere il tasto View una navigazione al dettaglio(true) o solo per aprire la dialog per l'edit(false)
        navigateToDetail: null,

        //Traduzioni
        translation: {},
        translationKey: "",

        //Props per la modalità mobile
        disableLeftAction: false,
        alphabeticGroupFilter: alphabeticGroupFilter,
        leftTextMobile: "",
        rightTextMobile: "",
        handleSearch: () => {
        },
        //Parola da inserire per confermare un delete
        confirmDeleteWord: "",
        DtoAdder: DtoAdder,
        //Props per lo splitButton
        splitButtonDataList: [],
        selectedIndex: null,
        enableSplitButton: false,

        //Props per il tabFilter
        enableTabFilter: false,
        selectedTabFilter: null,
        tabFilterArray: [],
    };

    dataApiCall = async (urlApiGet, dataList) => {
        if (dataList.length === 0) {
            let response = await BaseAxios.request({
                url: urlApiGet,
                method: "GET"
            });
            this.dataList = response.data;
            let {selectedTabFilter} = this.state;
            this.setTabFilter(selectedTabFilter);
            this.forceUpdate();
            this.refreshSkeleton(response.data.length, response.data);

        } else {
            this.refreshSkeleton(dataList.length, dataList);

        }

    };


    handleSubmit = async (e, dataToEdit, globalContext) => {//TODO add handlerError campi già usati e simili

        let {intl} = this.props;
        let {
            urlApiPost, urlApiPut, structCreateApi, translationKey, enableSplitButton, selectedIndex,
            splitButtonDataList, inputCustomId, translation, enableTabFilter, fieldToFilter, selectedTabFilter,
            setCustomInputFields, postSubmitAction
        } = this.state;
        let {resetDialogState, showDialog} = globalContext;
        try {
            this.setLoading(true, "BaseTableListPage - handleSubmit");
            showDialog(false);
            e.preventDefault();
            let newPatient;
            // 1. Creo l'input prendendo tutti i value dalla struttura dati compilata tramite il Dialog
            let input = structCreateApi;
            if (enableTabFilter) {
                input[fieldToFilter] = selectedTabFilter;
            }
            for (let field in structCreateApi) {
                if (e.target[field]) {
                    input[field] = e.target[field].value;
                }
            }
            if (setCustomInputFields) {
                input = setCustomInputFields(input)
            }
            if (enableSplitButton) {
                input[inputCustomId] = splitButtonDataList[selectedIndex].id;
            }
            if (dataToEdit) {
                await BaseAxios.request({
                    url: urlApiPut + dataToEdit.id,
                    method: "PUT",
                    data: input
                });
            } else {
                let res = await BaseAxios.request({
                    url: urlApiPost,
                    method: "POST",
                    data: input
                });
                newPatient = res.data;
            }
            resetDialogState();
            this.dataToEdit = null;
            this.forceUpdate();
            if (postSubmitAction) {
                postSubmitAction(globalContext, newPatient)
            }
        } catch (error) {
            console.error("Errore creating/editing new " + translationKey, error.response);
            let tmpEr;
            if (error && error.response && error.response.data && error.response.data.uuid) {
                tmpEr = error.response.data.uuid;
            } else if (error && error.response && error.response.status && error.response.statusText) {
                tmpEr = error.response.status + error.response.statusText;
            }
            this.setSnackbarOpen(
                "top",
                "right",
                true,
                2000,
                dataToEdit ? translation.handleSubmit.editError : translation.handleSubmit.createError
                ,
                "error", tmpEr
            );
            showDialog(true);
        } finally {
            await this.refreshData();
            this.setLoading(false, "BaseTableListPage - handleSubmit");
        }
    };


}

export default BaseTableListPage;