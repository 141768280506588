import {FormattedMessage} from "react-intl";
import React from "react";
import {
    FORM_COMPONENT_TYPE_DATE,
    FORM_COMPONENT_TYPE_SELECT,
    FORM_COMPONENT_TYPE_TEXT_INPUT
} from "@bit/tech4care.basiccomponents.constant-manager";
import {FORM_COMPONENT_TYPE_TEXT_AREA} from "@bit/tech4care.basiccomponents.constant-manager/ConstantManager";

const translationKey = "patientList";

export const reducedPatientDto = [
    {
        id: "name",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.name"}/>,
        required: true
    },
    {
        id: "surname",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.surname"}/>,
        required: true
    },
    {
        id: "gender",
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender"}/>,
        fields: [
            {
                value: "M",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender.M"}/>
            },
            {
                value: "F",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender.F"}/>
            },
            {
                value: "N",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender.N"}/>
            }
        ],
        required: true
    },
    {
        id: "birthDate",
        type: FORM_COMPONENT_TYPE_DATE,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.birthDate"}/>,
        required: true
    },
    {
        id: "identifierCode",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.identifierCode"}/>,
        required: true
    },
    {
        id: "email",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.email"}/>,
        required: true
    },
    {
        id: "phoneNumber",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.phoneNumber"}/>,
        required: true
    },

]

export const patientDto = [
    {
        id: "name",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.name"}/>,
        required: true
    },
    {
        id: "surname",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.surname"}/>,
        required: true
    },
    {
        id: "gender",
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender"}/>,
        toMap: true,
        fields: [
            {
                value: "M",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender.M"}/>
            },
            {
                value: "F",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender.F"}/>
            },
            {
                value: "N",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.gender.N"}/>
            }
        ],
        required: true
    },
    {
        id: "birthDate",
        type: FORM_COMPONENT_TYPE_DATE,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.birthDate"}/>,
        required: true
    },
    {
        id: "identifierCode",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.identifierCode"}/>,

    },
    {
        id: "address",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.address"}/>,

    },
    {
        id: "postcode",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.postcode"}/>,

    },
    {
        id: "city",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.city"}/>,

    },
    {
        id: "country",
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "it_IT",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.country"}/>,
        toMap: true,
        fields: [
            {
                value: "it_IT",//TODO forse è meglio un TEXT_FIELD?
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.country.it_IT"}/>
            }
        ],

    },
    {
        id: "education", //TODO probabilmente da rinominare
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.education"}/>,//TODO probabilmente da rinominare
        toMap: true,
        fields: [
            {
                value: "none",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.education.none"}/>
            },
            {
                value: "notSpecified",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.education.notSpecified"}/>
            },
            {
                value: "primaryEducation",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.education.primaryEducation"}/>
            },
            {
                value: "lowerSecondary",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.education.lowerSecondary"}/>
            },
            {
                value: "upperSecondary",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.education.upperSecondary"}/>
            },
            {
                value: "degree",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.education.degree"}/>
            },
            {
                value: "postDegree",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.education.postDegree"}/>
            },
        ],

    },
    {
        id: "occupation",
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.occupation"}/>,
        toMap: true,
        fields: [
            {
                value: "yes",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.occupation.yes"}/>
            },
            {
                value: "no",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.occupation.no"}/>
            },
        ],

    },
    {
        id: "email",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.email"}/>,
        required: true
    },
    {
        id: "phoneNumber",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.phoneNumber"}/>,
        required: true
    },
    {
        id: "phoneNumberAlternative",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.phoneNumberAlternative"}/>
    }
];


export const clinicalDataDto = [
    {
        id: "edss", //TODO probabilmente da rinominare
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.edss"}/>,//TODO probabilmente da rinominare
        fields: [
            {
                value: "0",
                label: "0"
            },
            {
                value: "1",
                label: "1"
            },
            {
                value: "2",
                label: "2"
            },
            {
                value: "3",
                label: "3"
            },
            {
                value: "4",
                label: "4"
            },
            {
                value: "5",
                label: "5"
            },
            {
                value: "6",
                label: "6"
            },
            {
                value: "7",
                label: "7"
            },
            {
                value: "8",
                label: "8"
            },
            {
                value: "9",
                label: "9"
            },
            {
                value: "10",
                label: "10"
            },
        ],
    },
    {
        id: "sickDuration",
        type: FORM_COMPONENT_TYPE_DATE,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.sickDuration"}/>
    },
    {
        id: "sickType",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.sickType"}/>
    },
];

export const doctorNotesDto = [{
    id: "note",
    type: FORM_COMPONENT_TYPE_TEXT_AREA,
    value: "",
    label: <FormattedMessage id={translationKey + ".commonDialog.fields.freeField"}/>,
    width:"29vw"
}]

export const statsDataDto = [
    {
        id: "sessionDone",
        type: null,
        value: "",
        label: null
    }, {
        id: "lastSession",
        type: null,
        value: "",
        label: null,
        formatDate: true
    }, {
        id: "totalRehabTime",
        type: null,
        value: "",
        label: null
    }, {
        id: "totalCorrectRepetition",
        type: null,
        value: "",
        label: null
    }, {
        id: "totalErrorRepetition",
        type: null,
        value: "",
        label: null
    }


];


export const sessionSettingsGeneralDto = [
    {
        id: "limb",
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.limb"}/>,
        toMap: true,
        fields: [
            {
                value: "right",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.limb.right"}/>
            },
            {
                value: "left",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.limb.left"}/>
            }
        ]

    },
    {
        id: "sessionLength",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.sessionLength"}/>
    },
    {
        id: "sessionProgrammed",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.sessionProgrammed"}/>
    }, {
        id: "frequency",
        type: FORM_COMPONENT_TYPE_TEXT_INPUT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.frequency"}/>
    }, {
        id: "period",
        type: FORM_COMPONENT_TYPE_SELECT,
        value: "",
        label: <FormattedMessage id={translationKey + ".commonDialog.fields.period"}/>,
        toMap: true,
        fields: [
            {
                value: "daily",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.period.daily"}/>
            },
            {
                value: "weekly",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.period.weekly"}/>
            }, {
                value: "monthly",
                label: <FormattedMessage id={translationKey + ".commonDialog.fields.period.monthly"}/>
            }]
    },
]


export const createPatient = {
    "identifierCode": null,
    "name": null,
    "surname": null,
    "birthCity": null,
    "birthCountry": null,
    "birthDate": null,
    "birthState": null,
    "gender": null,
    "phoneNumber": null,
    "postcode": null,
    "state": null,
    "city": null,
    "country": null,
    "email": null,
    "phoneNumberAlternative": null,
    "sickType": null,
    "sickDuration": null,
    "note": null,
    "sessionProgrammed": null,
    "sessionLength": null,
    "limb": null,
    "edss": null,
    "education": null,
    "occupation": null
};

export const editPatient = {
    "identifierCode": null,
    "name": null,
    "surname": null,
    "birthCity": null,
    "birthCountry": null,
    "birthDate": null,
    "birthState": null,
    "gender": null,
    "phoneNumber": null,
    "postcode": null,
    "state": null,
    "city": null,
    "country": null,
    "email": null,
    "phoneNumberAlternative": null,
    "sickType": null,
    "sickDuration": null,
    "note": null,
    "sessionProgrammed": null,
    "sessionLength": null,
    "limb": null,
    "edss": null,
    "education": null,
    "occupation": null
};