import ForgotPasswordPage from "@bit/tech4care.basiccomponents.forgot-password-page";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import BaseAxios from "../../../constants/ApiHelpers/BaseAxios";
import { API_FORGOT_PASSWORD } from "../../../constants/ApiHelpers/ApiConst";
import BaseComponent from "../../../interfaces/BaseComponent";
import {tableThemeOverride} from "../../../constants/TableColumns/TableSettingsCommon";

class ForgotPasswordScreen extends BaseComponent {
  handleSubmit = async (e) => {
    let { intl } = this.props;

    try {
      this.setLoading(true, "ForgotPasswordScreen - handleSubmit");
      e.preventDefault();
      await BaseAxios.request({
        url: API_FORGOT_PASSWORD + e.target["email"].value,
        method: "GET",
      });
      this.setSnackbarOpen(
        "top",
        "right",
        true,
        2000,
        intl.formatMessage({
          id: "forgotPassword.success",
        }),
        "success"
      );
    } catch (error) {
      console.error("Error during reset password: ", error.response);
      this.setSnackbarOpen(
        "top",
        "right",
        true,
        2000,
        intl.formatMessage({
          id: "forgotPassword.error",
        }),
        "error",
        error.response.data.uuid
          ? error.response.data.uuid
          : error.response.status + error.response.statusText
      );
    } finally {
      this.setLoading(false, "ForgotPasswordScreen - handleSubmit");
    }
  };

  renderForgotPasswordPage = () => {
    let { intl, history } = this.props;
    return (
      <ForgotPasswordPage
        commonThemeConstant={tableThemeOverride}
        signInButtonColor={"secondary"}
        labelList={{
          submitButtonForgotPassword: intl.formatMessage({
            id: "forgotPassword.sendButton",
          }),
          emailPlaceHolderForgotPassword: intl.formatMessage({
            id: "forgotPassword.emailPlaceholder",
          }),
          textContentForgotPassword: intl.formatMessage({
            id: "forgotPassword.description.subtitle",
          }),
          titleForgotPassword: intl.formatMessage({
            id: "forgotPassword.description.title",
          }),
        }}
        handleSubmit={async (e) => {
          await this.handleSubmit(e);
        }}
        history={history}
      />
    );
  };
  renderMobile = () => {
    return this.renderForgotPasswordPage();
  };

  renderDesktop = () => {
    return this.renderForgotPasswordPage();
  };
}

export default injectIntl(withRouter(ForgotPasswordScreen));
