import {Button, Link} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import style from "../../../assets/styles/TableCommon.module.css";
import moment from "moment";
import {tableStyle} from "../TableSettingsCommon";
import {primaryColor} from "../../../assets/theme/ThemeConstants";

const translationKey = "patientList";

export const getPatientListColumns = (intl,deleteAction,viewAction) => {
    return ([
        {
            title: intl.formatMessage({id: translationKey + ".fieldTitle.name"}),
            field: "pdPatientDto.completeName",
            ...tableStyle(true),
            render: rowData => {

                return  <Button
                    className={style.avatarNameContainer}
                    variant={"text"}
                    color={"primary"}
                    onClick={() => {
                        viewAction(rowData);
                    }}
                >
                    <svg
                        width="40"
                        height="40"
                        data-jdenticon-value={rowData.pdPatientDto.name}
                        viewBox="0 0 40 40"
                        preserveAspectRatio="xMidYMid meet"
                    />
                    <label>{rowData.pdPatientDto.name+ " " + rowData.pdPatientDto.surname}</label>
                </Button>
        }},
        {
            title: intl.formatMessage({id: translationKey + ".fieldTitle.birthDate"})
            ,
            field: "pdPatientDto.birthDate",
            ...tableStyle(),
            sorting: false,
        },
        {
            title: intl.formatMessage({id: translationKey + ".fieldTitle.creationDate"}),
            field:
                "pdPatientDto.insertDate",
            ...tableStyle(),
            render:
                rowData => {
                    return (
                        <label>{moment(rowData.pdPatientDto.insertDate).format("YYYY-MM-DD")}</label>
                    )
                }
        }
        ,
        {
            title: intl.formatMessage({id: translationKey + ".fieldTitle.lastSession"}),
            field: "pdPatientDto.lastSession",
            ...tableStyle(),
            render:
                rowData => {
                    if (!rowData.pdPatientDto.lastSession) {
                        return <label><FormattedMessage id={translationKey + ".table.noData"}/> </label>
                    }

                    return (
                        <label>{moment(rowData.pdPatientDto.lastSession).format("YYYY-MM-DD")}</label>
                    )
                }
        }
        ,

        {
            title: intl.formatMessage({id: translationKey + ".fieldTitle.phoneNumber"}),
            field: "pdPatientDto.phoneNumber",
            ...tableStyle(),
        }
        ,
        {
            title: intl.formatMessage({id: translationKey + ".fieldTitle.email"}),
            field:
                "pdPatientDto.email",
            ...tableStyle(),
        }
        ,
        {
            title: intl.formatMessage({id: translationKey + ".fieldTitle.actions"}),
            field:
                "actions",
            ...tableStyle(),

            sorting: false,
            export:
                false,
            render:
                rowData => {
                    return (
                        <div
                            id={"actionButtonContainer"}
                            className={style.actionButtonContainer}
                        >
                            {/*<Button*/}
                            {/*    variant={"text"}*/}
                            {/*    color="secondary"*/}
                            {/*    onClick={() => {*/}
                            {/*        deleteAction(rowData);*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <FormattedMessage id={translationKey + ".table.field.delete"}/>*/}
                            {/*</Button>*/}
                            <Button
                                variant={"contained"}
                                style={{backgroundColor: primaryColor}}
                                color={"primary"}
                                onClick={() => {
                                    viewAction(rowData);
                                }}
                            >
                                <FormattedMessage id={translationKey + ".table.field.view"}/>
                            </Button>
                        </div>
                    )
                }
        }
    ])
        ;
};
