import React from "react";

export const AUTH_TOKEN_ID = "MAGIC_TOKEN";
export const FUTURE_UPDATE =false;
// export const BASE_PATH = "http://localhost:8081/magicadvanced-1.0.0";
export const BASE_PATH = "https://api-test.tech4care.eu/magicadvanced-1.0.0";

//CONTEXT
export const GlobalContext = React.createContext();

export const TOP_BAR_BUTTON_WEB = "WEB";

export const DIALOG_ACTION_EDIT="EDIT";

export const PATIENT_DETAIL_TABS={
    PATIENT_INFO:0,
    REHAB_PLAN:1,
    STATS:2,
    ACTIVITY_LOG:3
}


export const FEEDBACK_DIALOG_OPEN="feedbackDialogOpen";
export const USER_DIALOG_OPEN="userDialogOpen";
export const PERSONAL_DATA_DIALOG_OPEN="personalDataDialogOpen";
export const DOCTOR_NOTES_DIALOG_OPEN="doctorNotesDialogOpen";
export const CLINICAL_DATA_DIALOG_OPEN="clinicalDataDialogOpen";
export const EXERCISES_SETTINGS_DIALOG_OPEN="exercisesSettingsDialogOpen";
export const SESSION_SETTINGS_GENERAL_DIALOG_OPEN="sessionSettingsGeneralDialogOpen";

//TODO mettere su bit constantManager
export const DISMISS_CHECK = "DIMETTI";
export const DELETE_CHECK = "ELIMINA";
export const REMOVE_CHECK = "RIMUOVI";
export const ACCEPT_CHECK = "ACCETTA";
export const REJECT_CHECK = "RIFIUTA";
export const RECOVER_CHECK = "RIPRISTINA";
export const DEFAULT_CHECK = "DEFAULT";
export const DENY_CHECK = "DECLINA";

export const NO_SEARCH_RESULT = "noData";

export const ALPHABET_LIST_LETTERS = [
    // "1", "2","3", "4", "5", "6", "7", "8", "9", "0",
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
];