import React, {Component} from 'react';
import {Route,withRouter} from "react-router-dom";

import {LOGGED_IN_ROUTER_PATIENT_DETAIL} from "../constants/NavigationConstants";
import PatientList from "../screens/LoggedIn/PatientListScreen/PatientList";
import PatientDetail from "../screens/LoggedIn/PatientListScreen/PatientDetail";

class PatientListRouter extends Component {
    render() {
        let {enableSecondaryTopbar,match}=this.props;
        console.debug("[PatientListRouter] render: passo per",match)
        return (
            <>
                <Route exact path={match.path}>
                    <PatientList enableSecondaryTopbar={enableSecondaryTopbar} />
                </Route>
                <Route
                    path={
                        match.path+  LOGGED_IN_ROUTER_PATIENT_DETAIL.PATH
                    }
                >
                    <PatientDetail enableSecondaryTopbar={enableSecondaryTopbar}/>
                </Route>

            </>
        );
    }
}

export default withRouter(PatientListRouter);
