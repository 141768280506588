import React, {Component} from 'react';
import {Checkbox, TextField} from "@material-ui/core";

import style from "../assets/styles/FourColumnEditableList.module.css";
import {FormattedMessage} from "react-intl";

class FourColumnEditableList extends Component {


    renderContentList = (item,index) => {
        let { customStyle, onChangeCheckBox,contentValuesList,onChangeTextField,translationKey} = this.props;
        return <div id={"rowContainer"}
                    style={customStyle.rowContainer ? customStyle.rowContainer : undefined}
                    className={item?index % 2 === 0 ? style.rowContainerOdd : style.rowContainerEven:style.rowContainerTitle}>
            <div id={"columnZero"} style={customStyle.columnZero ? customStyle.columnZero : undefined}
                 className={item ? style.columnZero : style.columnZeroTitle}>
                {item ? <Checkbox
                    onChange={(event, checked)=>{onChangeCheckBox(event,checked,item,index,contentValuesList)}}
                    checked={item.enabled}
                    color="default"
                    value="default"
                    inputProps={{'aria-label': 'checkbox with default color'}}
                /> : <label id={"columnZeroTitle"} className={style.columnZeroTitle}><FormattedMessage id={translationKey+".commonDialog.fields.exercise.active"}/> </label>}
            </div>
            <div id={"avatarColumn"} style={customStyle.avatarColumn ? customStyle.avatarColumn : undefined}
                 className={style.avatarColumn}>
                {item ? <img id={"avatar"}
                             src={item.avatar}
                             style={customStyle.avatar ? customStyle.avatar : undefined}
                             className={style.avatar}>
                </img> : <label id={"avatarColumnTitle"} className={style.avatarColumnTitle}><FormattedMessage id={translationKey+".commonDialog.fields.exercise.icon"}/></label>}
            </div>
            <div id={"firstColumn"} style={customStyle.firstColumn ? customStyle.firstColumn : undefined}>
                {item ? <label id={"value1Label"}
                               style={customStyle.value1Label ? customStyle.value1Label : undefined}
                               className={style.value1Label}>
                    {item.value1}
                </label> : <label id={"firstColumnTitle"} className={style.firstColumnTitle}><FormattedMessage id={translationKey+".commonDialog.fields.exercise.name"}/></label>}
            </div>
            <div id={"secondColumn"} style={customStyle.secondColumn ? customStyle.secondColumn : undefined}
                 className={style.secondColumn}>
                {item ?item.enabled? <TextField id="value2Label"

                                   style={customStyle.value2Label ? customStyle.value2Label : undefined}
                                   className={style.value2Label}
                                                onChange={(event)=>{onChangeTextField(event,item,index,contentValuesList)}}
                                   defaultValue={item.value2}/>:null :
                    <label id={"secondColumnTitle"} className={style.secondColumnTitle}><FormattedMessage id={translationKey+".commonDialog.fields.exercise.goalRepetition"}/></label>}
            </div>
        </div>
    };

    render() {
        let {contentValuesList, customStyle} = this.props;
        return (
            <div id={"contentContainer"}
                 style={customStyle.contentContainer ? customStyle.contentContainer : undefined}
                 className={style.contentContainer}>
                <div id={"columnsTitle"} style={customStyle.columnsTitle ? customStyle.columnsTitle : undefined}
                     className={style.columnsTitle}>
                    {this.renderContentList(null,1)}
                </div>
                <div id={"contentListContainer"}
                     style={customStyle.contentListContainer ? customStyle.contentListContainer : undefined}
                     className={style.contentListContainer}>
                    {contentValuesList.map((item, index) => {

                        return this.renderContentList(item,index)
                    })}
                </div>

            </div>
        );
    }
}

FourColumnEditableList.defaultProps = {
    // contentValuesList: [
    //     {
    //         avatar: null,
    //         value1: "Lvl1",
    //         value2: "10",
    //         enable: true
    //     },
    //     {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "5",
    //         enable: true
    //     },
    //     {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "5",
    //         enable: true
    //     },
    //     {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "2",
    //         enable: false
    //     },
    //     {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "5",
    //         enable: false
    //     },
    //     {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "10",
    //         enable: false
    //     },
    //     {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "12",
    //         enable: false
    //     }, {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "12",
    //         enable: false
    //     }, {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "12",
    //         enable: false
    //     }, {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "12",
    //         enable: false
    //     }, {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "12",
    //         enable: false
    //     }, {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "12",
    //         enable: false
    //     }, {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "12",
    //         enable: false
    //     },
    //     {
    //         avatar: null,
    //         value1: "Lvl2",
    //         value2: "17",
    //         enable: false
    //     }],
    customStyle: {
        rowContainer: null,
        value1Label: null,
        value2Label: null,
        value3Label: null,
        firstColumn: null,
        secondColumn: null,
        thirdColumn: null,
        columnZero: null,
        avatarColumn: null
    }
}

export default FourColumnEditableList;