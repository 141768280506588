import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {LOGGED_IN_ROUTER_PATIENT_DETAIL} from "../../../constants/NavigationConstants";
import BaseAxios from "../../../constants/ApiHelpers/BaseAxios";
import {
    API_CREATE_PATIENT,
    API_DELETE_PATIENT,
    API_EDIT_PATIENT,
    API_GET_CUSTOMERS_INFO
} from "../../../constants/ApiHelpers/ApiConst";
import {createPatient, reducedPatientDto} from "../../../constants/DialogsDto/PatientDto";
import {DELETE_CHECK} from "../../../constants/CommonConstants";
import BaseTableListPage from "../../../interfaces/BaseTableListPage";
import {tableLocalization, tableOptions} from "../../../constants/TableColumns/TableSettingsCommon";
import {getPatientListColumns} from "../../../constants/TableColumns/PatientList/PatientListColumns";


class PatientList extends BaseTableListPage {
    state = {
        ...this.state,
        filtersPopupOpen: {
            buyers: false
        },
        filter: {
            datasetField: null,
            datasetFieldValues: []
        },
        newPatientId: null,
        translation: this.translationTableListPage("patientList", this.props.intl),
        getTableColumns: (deleteAction, viewAction) => {
            return getPatientListColumns(this.props.intl, deleteAction, viewAction)
        }

    };

    async componentDidMount() {
        this.setLoading(true, "PatientList - componentDidMount");
        let {enableSecondaryTopbar, intl} = this.props;
        //0. Pulizia della topbar nel caso stesso tornando indietro da PatientDetail
        if (enableSecondaryTopbar) {
            enableSecondaryTopbar(false);
        }

        this.setState({
                urlApiGet: API_GET_CUSTOMERS_INFO,
                urlApiPost: API_CREATE_PATIENT,
                urlApiPut: API_EDIT_PATIENT,
                urlApiDelete: API_DELETE_PATIENT,
                structCreateApi: createPatient,
                structDialog: reducedPatientDto,
                leftTextMobile: "name",
                rightTextMobile: "phone",
                confirmDeleteWord: DELETE_CHECK,
                tableLocalization: tableLocalization(intl),
                tableOptions: tableOptions,
                postSubmitAction: this.renderFeedbackCreationPatient,
                navigateToDetail: (rowData) => {
                    let {history, match} = this.props;
                    history.push({
                        pathname: match.path
                            + LOGGED_IN_ROUTER_PATIENT_DETAIL.PATH,
                        state: {
                            patientId: rowData.pdPatientDto.customerId
                        }
                    })
                }
            },
            () => {
                BaseAxios.request({
                    url: API_GET_CUSTOMERS_INFO,
                    method: "GET"
                }).then((response) => {
                    if (response && response.data) {
                        this.dataList = response.data;
                        this.skeletonRowsNumber = this.dataList.length;
                        this.forceUpdate(() => {
                            this.refreshData(this.dataList)
                        })
                    }
                });
            }
        );

    }


    renderFeedbackCreationPatient = (globalContext, newPatient) => {
        let {setDialogState, resetDialogState} = globalContext;
        setDialogState({
            dialogIsOpen: true,
            handleClose: () => {
                resetDialogState()
            },
            handleSubmit: async (e) => {
                e.preventDefault();
                resetDialogState();
                let {history, match} = this.props;
                history.push({
                    pathname: match.path
                        + LOGGED_IN_ROUTER_PATIENT_DETAIL.PATH,
                    state: {
                        patientId: newPatient
                    }
                })
            },
            leftButtonText: <FormattedMessage id={"feedbackDialog.button.completeLater"}/>,
            rightButtonText: <FormattedMessage id={"feedbackDialog.button.completeNow"}/>,
            title: <FormattedMessage id={"feedbackDialog.title"}/>,
            content: <h6><FormattedMessage id={"feedbackDialog.description"}/></h6>

        });

    }

}


export default injectIntl(withRouter(PatientList));
