import React from 'react';
import InformationListCard from "@bit/tech4care.basiccomponents.information-list-card"
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import Save from "@material-ui/icons/Save";
import CommonFormNb from "@bit/tech4care.basiccomponents.common-form-nb";
import style from "../../assets/styles/PatientInfo.module.css";
import Grid from "@material-ui/core/Grid";
import BaseComponent from "../../interfaces/BaseComponent";
import {informationListCardStyle} from "../../assets/reactStyles/personalDataStyle";




class PatientInfo extends BaseComponent {



    componentDidMount() {
        this.setLoading(true)
        let {refreshPatientData} = this.props;

        if (refreshPatientData) {
            refreshPatientData();
        }
        this.setLoading(false)
    }



    renderDesktop = (globalContext) => {
        let {personalData, clinicalData, doctorNotes, personalDataDialog, handleSubmitEdit, clinicalDataDialog, doctorNotesDialog,  renderAddNewDialog} = this.props;


        return (
            <Grid container spacing={5}
                  justify={"flex-start"} id={"mainContainer"}
            >
                <Grid item xs={"auto"} sm={"auto"}>
                    <InformationListCard
                        customStyle={informationListCardStyle()}
                        id={"personalData"}
                        headerComponent={<label className={style.cardsTitle}><FormattedMessage
                            id={"patientDetail.card.personalData.title"}/></label>}
                        contentValuesList={personalData}
                        footerComponent={<Button onClick={() => {
                            renderAddNewDialog(globalContext, false, personalDataDialog, handleSubmitEdit)
                        }} startIcon={<Edit/>}
                                                 variant={"contained"}
                                                 color={"primary"}><FormattedMessage
                            id={"patientDetail.button.edit"}/></Button>}/>
                </Grid>
                <Grid item xs={"auto"} sm={"auto"}>
                    <InformationListCard
                        customStyle={
                            informationListCardStyle({
                                mainContainer: {
                                    height: "350px"
                                },
                                headerContainer: {
                                    height: "50px",
                                },
                                contentListContainer: {
                                    height: "120px"
                                },
                                footerContainer: {height: "200px", paddingTop: "80px"},
                            })
                        }
                        id={"clinicalData"}
                        headerComponent={<label className={style.cardsTitle}><FormattedMessage
                            id={"patientDetail.card.clinicalData.title"}/></label>}
                        contentValuesList={clinicalData}
                        footerComponent={<Button onClick={() => {
                            renderAddNewDialog(globalContext, false, clinicalDataDialog, handleSubmitEdit)
                        }} startIcon={<Edit/>}
                                                 variant={"contained"}
                                                 color={"primary"}><FormattedMessage
                            id={"patientDetail.button.edit"}/></Button>}/>
                </Grid>
                <Grid item xs={"auto"} sm={"auto"}>
                    <InformationListCard
                        customStyle={
                            informationListCardStyle({
                                mainContainer: {height: "614px"},
                                headerContainer: {
                                    height: "50px",
                                },
                                contentContainer: {
                                    height: "490px",
                                    padding: "10px 10px"
                                },

                            })
                        }
                        id={"doctorNotes"}
                        headerComponent={<label className={style.cardsTitle}><FormattedMessage
                            id={"patientDetail.card.doctorNotes.title"}/></label>}
                        contentComponent={
                            <label
                                className={style.textArea}>{doctorNotes && doctorNotes[0] && doctorNotes[0].value}</label>
                        }

                        footerComponent={<Button onClick={() => {
                            renderAddNewDialog(globalContext, false, doctorNotesDialog, handleSubmitEdit)
                        }} startIcon={<Edit/>}
                                                 variant={"contained"}
                                                 color={"primary"}><FormattedMessage
                            id={"patientDetail.button.edit"}/></Button>}
                    />
                </Grid>
            </Grid>

        );
    }
}

export default injectIntl(PatientInfo);