//0. LOADING_SCREEN
export const LOADING_SCREEN = {
    ID: "loading-screen",
    PATH: "/"
}

//1. LOGGED_OUT_ROUTER AND CHILDREN
export const LOGGED_OUT_ROUTER = {
    ID: "logged-out",
    PATH: "/loggedOut/"
};
export const LOGGED_OUT_ROUTER_LOGIN_SCREEN = {
    ID: "authentication",
    PATH: ""
};
export const LOGGED_OUT_ROUTER_FORGOT_PASSWORD_SCREEN = {
    ID: "forgot-password",
    PATH: "forgotPassword"
};
export const LOGGED_OUT_ROUTER_RESET_PASSWORD_SCREEN = {
    ID: "reset-password",
    PATH: "resetPassword"
};
export const LOGGED_OUT_ROUTER_TERMS_AND_CONDITIONS_SCREEN = {
    ID: "terms-and-condition-out",
    PATH: "termsAndConditionsOut"
};

//2.  AND CHILDRENLOGGED_IN_ROUTER
export const LOGGED_IN_ROUTER = {
    ID: "logged-in",
    PATH: "/loggedIn/"
};
export const LOGGED_IN_ROUTER_STORE_MAIN_SCREEN = {
    ID: "main-screen",
    PATH: ""
};
export const LOGGED_IN_ROUTER_PROFILE_PAGE = {
    ID: "profilePage",
    PATH: "profilePage/"
};

export const LOGGED_IN_ROUTER_PATIENT_ROUTER = {
    ID: "patient-list-router",
    PATH: ""
};
export const LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN = {
    ID: "patient-list",
    PATH: "patientList/"
};
export const LOGGED_IN_ROUTER_PATIENT_DETAIL = {
    ID: "patient-detail",
    PATH: "patientDetail/"
};
