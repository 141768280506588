import {FormattedMessage} from "react-intl";
import React from "react";
import {BASE_THEME_CUSTOMIZATION, primaryColor} from "../../assets/theme/ThemeConstants";

export const tableStyle = (isBold,centerText) => {
    return {
      cellStyle: {
          textAlign:centerText?"center":undefined,
        paddingLeft: 10,
        fontWeight: isBold ? "bold" : undefined,
      },
      headerStyle: {
          textAlign:centerText?"center":undefined,
        paddingLeft: 15,
        font: "var(--text-font-h8)",
        textTransform: "capitalized",
      },
    };
  };
  
export let tableOptions = (pageSizeOptions) => {

    return {
        emptyRowsWhenPaging: false,
        pageSizeOptions: pageSizeOptions,
        searchFieldAlignment: "left",
        selection: false,
        sorting: true,
        selectionProps: rowData => ({
            color: "primary",
            borderColor: "primary", marginLeft: "2vw"
        }),
        exportButton: false,
        headerStyle: {
            fontWeight: "bold",
            textTransform: "uppercase",
            backgroundColor: "#F0F0F0",
            paddingLeft: "1vw",
            paddingRight: "0.5vw",
            marginTop: "auto",
            marginBottom: "auto",
        },
        rowStyle: {

            borderTop: "1px solid #E4E4E4",
            borderBottom: "1px solid #E4E4E4"
        },
        actionsColumnIndex: -1,
        showTitle: false,
        showSelectAllCheckbox: false,
        showTextRowsSelected: false,
    }

};


export const tableThemeOverride = (isMobile, customOverride = {}) => {

    return {
        palette: {
            ...BASE_THEME_CUSTOMIZATION.palette
        },
        typography: {
            ...BASE_THEME_CUSTOMIZATION.typography
        },
        overrides: {
            ...BASE_THEME_CUSTOMIZATION.overrides,
            MuiDialogContent: {root: {height: isMobile ? "60vh" : undefined}},
            MuiFormControl: {
                root: {
                    boxShadow: "0px 3px 6px #00000029 !important",
                    borderRadius: "4px !important"
                }
            },
            MuiButton: {
                containedPrimary: {
                    backgroundColor: primaryColor
                }
            },
            MuiOutlinedInput: {
                root: {
                    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                        borderColor: "var(--first-color)"
                    },
                },
            },
            MuiInput: {

                root: {
                    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                        borderColor: "var(--first-color)"
                    },
                },
                underline: {
                        paddingLeft: "15px",
                    '&:after': {
                        borderBottom: `2px solid var(--first-color)`,
                    },
                    '&$focused:after': {
                        borderBottomColor: "var(--first-color)",
                    },
                    '&$error:after': {
                        borderBottomColor: `var(--first-color)`,
                    },
                    '&:before': {
                        borderBottom: `var(--first-color)`,
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottom: `2px solid var(--first-color)`,
                    },
                    '&$disabled:before': {
                        borderBottom: `1px dotted var(--first-color)`,
                    },
                },

            },
            MuiIcon: {
                fontSizeSmall: {
                    color: "#748094"
                }
            },
            MuiInputBase: {
                input: {
                    fontSize: "14px"
                }
            },
            ...customOverride
        }
    }
};

export const tableLocalization = (intl) => {
        return {
            pagination: {
                labelDisplayedRows: intl.formatMessage({id: "baseTableListPage.table.logs.recordsNumberOfTotal"}),
                labelRowsSelect:
                    intl.formatMessage({id: "baseTableListPage.table.logs.labelRowsSelect"}),
                firstTooltip:
                    intl.formatMessage({id: "baseTableListPage.table.logs.firstTooltip"}),
                previousTooltip:
                    intl.formatMessage({id: "baseTableListPage.table.logs.previousTooltip"}),
                nextTooltip:
                    intl.formatMessage({id: "baseTableListPage.table.logs.nextTooltip"}),
                lastTooltip:
                    intl.formatMessage({id: "baseTableListPage.table.logs.lastTooltip"})
            }
            ,
            body: {
                emptyDataSourceMessage: <FormattedMessage
                    id="baseTableListPage.table.logs.noRecords"/>,
                filterRow: {
                    filterTooltip: <
                        FormattedMessage
                        id="baseTableListPage.table.logs.tooltip.filter"/>
                }
                ,
            }
            ,
            toolbar: {
                searchPlaceholder:
                    intl.formatMessage({id: "baseTableListPage.table.form.searchPlaceholder"})
            }
        }
    }
;