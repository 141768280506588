import Action from "../Action";
import {SET_ERROR_TIME } from "../actionKeyConst";

 export const setErrorTime = (handleError,error) => async dispatch => {
     try{
         let action = new Action(dispatch, SET_ERROR_TIME);
         action.success(error);
         console.error("[systemVariables] setErrorTime - success: ", error);
     }catch(error){
         console.error("[systemVariables] setErrorTime - Error: ", error.message);
         if (handleError) {
             handleError(error);
         }
     }
 };
