export const handleMyInfo = response => {
    if (response && response.data && response.data.personalDataDto) {
        let {name, surname, src, email} = response.data.personalDataDto;

        let nameInitial = name && name.charAt(0) ? name.charAt(0) : "";
        let surnameInitial = surname && surname.charAt(0) ? surname.charAt(0) : "";

        let userData = {
            avatar: {
                src: src,
                initials: (nameInitial + "." + surnameInitial+".").toUpperCase()
            },
            email: email,
            name: name + " " + surname
        };

        // ESEMPIO DI COME DOVREBBE ESSERE LA STRINGA
        // userData.src =
        // 	"data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==";


        return userData;
    } else {
        throw new Error("No data from the API");
    }
};
