export const informationListCardStyle = (custom) => {
    return {
        mainContainer: {
            height: "auto",
            ...(custom && custom.mainContainer),
            border: "1px solid var(--information-list-card-border-color)",
        },
        contentListContainer: {
            height: "auto",
            ...(custom && custom.contentListContainer)
        },
        rowContainer: {
            borderRight: "0px",
            borderBottom: "1px solid var(--information-list-card-border-color)",
            height: "35px",
            ...(custom && custom.rowContainer)
        },
        contentValue: {
            font: "var(--text-font-h7)",
            color: "var(--title-color)",
            ...(custom && custom.contentValue)
        },
        contentKey: {
            font: "var(--text-font-h8)",
            ...(custom && custom.contentKey)
        },
        footerContainer: {
            height: "72px",
            paddingLeft: "20px",
            ...(custom && custom.footerContainer)
        },
        headerContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
            ...(custom && custom.headerContainer)
        },
        contentContainer: {
            ...(custom && custom.contentContainer)
            }


        }
};


