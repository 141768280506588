import React from 'react';
import style from "../../assets/styles/PatientInfo.module.css";
import InformationListCard from "@bit/tech4care.basiccomponents.information-list-card";
import {FormattedMessage, injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import InformationListTitleCard from "@bit/tech4care.basiccomponents.information-list-title-card";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {informationListCardStyle} from "../../assets/reactStyles/personalDataStyle";
import FilteredTable from "@bit/tech4care.basiccomponents.filtered-table";
import {getPatientStatColumns, patientStatsOptions} from "../../constants/TableColumns/PatientStats/PatientStatColumns";
import {tableThemeOverride} from "../../constants/TableColumns/TableSettingsCommon";
import BaseComponent from "../../interfaces/BaseComponent";


const customTheme = createMuiTheme(tableThemeOverride());


class PatientStats extends BaseComponent {
    componentDidMount() {
        let {refreshPatientData} = this.props;

        if (refreshPatientData) {
            refreshPatientData();

        }
    }


    renderDesktop = () => {
        let {statsData, exerciseStats, intl} = this.props;

        let columns = getPatientStatColumns(intl);


        let pageSizeOptions = [];
        if (exerciseStats) {
            for (let pageSizeOptionItem in [5, 10, 20]) {
                if (exerciseStats.length > pageSizeOptionItem) {
                    pageSizeOptions.push(pageSizeOptionItem);
                } else if (pageSizeOptions.length === 0) {
                    pageSizeOptions.push(exerciseStats.length);
                    break;
                } else {
                    break;
                }
            }
        }
        return (
            <ThemeProvider theme={customTheme}>
                <Grid container spacing={5} justify={"flex-start"}>
                    <Grid item xs={"auto"}>
                        <InformationListCard
                            customStyle={
                                informationListCardStyle(
                                    {
                                        mainContainer: {height: "614px"},
                                        headerContainer: {
                                            height: "50px",
                                        },
                                        contentListContainer: {height: "490px",},
                                        rowContainer: {
                                            height: "60px"
                                        }
                                    }
                                )
                            }
                            id={"personalData"}
                            headerComponent={<label className={style.cardsTitle}><FormattedMessage
                                id={"patientDetail.card.statsData.title"}/></label>}
                            contentValuesList={statsData}
                        />
                    </Grid>
                    <Grid item xs={"auto"}>
                        <InformationListCard
                            customStyle={{
                                mainContainer: {width: "1000px", height: "614px"},
                                headerContainer: {
                                    height: "210px",
                                    borderBottom: "2px solid var(--information-list-card-border-color)"
                                },
                                contentContainer: {
                                    overflow: "auto",
                                    height: "403px",
                                    paddingLeft: 0

                                },
                                footerContainer: {paddingLeft: "1vw", height: "80px"}
                            }}
                            headerComponent={<InformationListTitleCard
                                customStyle={{

                                    mainContainer: {
                                        width: "1000px", height: "210px", border: "0px",
                                        borderTopRightRadius: "5px",
                                        borderTopLeftRadius: "5px",
                                    },
                                    headerContainer: {
                                        height: "35px",
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    },
                                    contentContainer: {
                                        height: "125px",
                                        border: "0px"
                                    },
                                    footerContainer: {
                                        height: "0px"
                                    },


                                }}
                                sectionTitle={<label className={style.cardsTitle}><FormattedMessage
                                    id={"patientDetail.card.exercisesSettings.title"}/></label>}
                                footerContent={[]}
                            />}
                            contentComponent={
                                <FilteredTable
                                    columns={columns} data={exerciseStats || []}
                                    options={patientStatsOptions(pageSizeOptions)}
                                />
                            }
                            id={"exercisesSettings"}
                            contentValuesList={exerciseStats}

                        />
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}

export default injectIntl(PatientStats);
