import React, {Component} from 'react';
import {IonLoading} from "@ionic/react";
import {injectIntl} from "react-intl";

class LoadingMobile extends Component {

    lMobile = null;

    render() {
        let {intl, loading} = this.props;

        if (this.lMobile && !loading) {
            this.lMobile.dismiss();
        }

        return (
            <IonLoading
                ref={x => this.lMobile = x}
                isOpen={loading}
                message={intl.formatMessage({id: "loading.mobile.pleaseWait"})}
            />
        );
    };


}

export default injectIntl(LoadingMobile);