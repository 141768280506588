import style from "../../../assets/styles/PatientInfo.module.css";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import React from "react";
import {tableStyle} from "../TableSettingsCommon";
import {textColor} from "../../../assets/theme/ThemeConstants";

export const getPatientStatColumns = (intl) => {
    return ([
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.level"})
            ,
            field: "levelName",
            ...tableStyle(true),
            render: rowData => {
                return <div id={"avatarLevelNameContainer"} className={style.avatarLevelNameContainer}><img
                    className={style.avatar}
                    src={"data:image/png;base64," + rowData.levelImage}/><label>{rowData.levelName}</label></div>
            }

        },
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.repetition"})
            ,
            field: "repetitions",
            ...tableStyle(),
        },
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.avgTime"})
            ,
            field: "avgTime",
            ...tableStyle(),
            render:
                rowData => {

                    if (!rowData.avgTime) {
                        return <label><FormattedMessage id={"patientDetail.exercises.noData"}/> </label>
                    }

                    return <label>{moment(rowData.avgTime).format("mm'ss''")}</label>
                }
        },

        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.bestTime"})
            ,
            field: "bestTime",
            ...tableStyle(),
            render:
                rowData => {

                    if (!rowData.bestTime) {
                        return <label><FormattedMessage id={"patientDetail.exercises.noData"}/> </label>
                    }

                    return <label>{moment(rowData.bestTime).format("mm'ss''")}</label>
                }
        },
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.worstTime"})
            ,
            field: "worstTime",
            ...tableStyle(),
            render:
                rowData => {

                    if (!rowData.worstTime) {
                        return <label><FormattedMessage id={"patientDetail.exercises.noData"}/> </label>
                    }

                    return <label>{moment(rowData.worstTime).format("mm'ss''")}</label>
                }
        },
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.lastTime"})
            ,
            field: "lastTime",
            ...tableStyle(),
            render:
                rowData => {

                    if (!rowData.lastTime) {
                        return <label><FormattedMessage id={"patientDetail.exercises.noData"}/> </label>
                    }

                    return <label>{moment(rowData.lastTime).format("mm'ss''")}</label>
                }
        },
        {
            title:
                intl.formatMessage({id: "patientDetail.exercises.error"})
            ,
            field: "errors",
            ...tableStyle(),
        },


    ])
        ;
};

export const patientStatsOptions = (pageSizeOptions) => {
    return {
        emptyRowsWhenPaging: false,
        pageSizeOptions: pageSizeOptions,
        search: false,
        paging: false,
        searchFieldAlignment: "left",
        selection: false,
        sorting: false,
        toolbar: false,
        selectionProps: (rowData) => ({
            color: "primary",
            borderColor: "primary", marginLeft: "2vw"
        }),

        headerStyle: {
            fontWeight: "bold",
            textTransform: "uppercase",
            backgroundColor: "#FFFFFF",
            color: textColor,
            paddingLeft: "1vw",
            paddingRight: "0.5vw",
            marginTop: "auto",
            marginBottom: "auto",
        },
        rowStyle: {
            borderTop: "1px solid #E4E4E4",
            borderBottom: "1px solid #E4E4E4"
        },
        actionsColumnIndex: -1,
        showTitle: false,
        showSelectAllCheckbox: false,
        showTextRowsSelected: false,
    }
}