import React from "react";
import {withRouter} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import {FormattedMessage} from "react-intl";
import {MENU_ELEMENTS} from "../../../constants/LayoutConstants";
import {LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN} from "../../../constants/NavigationConstants";
import PatientListRouter from "../../../routing/PatientListRouter";
import BaseScreen from "../../../interfaces/BaseScreen";


class PatientListScreen extends BaseScreen {


    state = {
        ...this.state,
        secondaryTopBarEnabled: false,
        name: "",
        surname: "",
        backArrowEnabled: true,
        pageTitle: <FormattedMessage id={"patientList.pageTitle"}/>
    }


    componentDidMount() {
        this.setLoading(true, "PatientListScreen - componentDidMount")
        let {secondaryTopBarEnabled} = this.state;
        this.setState({
            menuElements: MENU_ELEMENTS,
            currentPageId: LOGGED_IN_ROUTER_PATIENT_LIST_SCREEN.ID,
            content: <PatientListRouter enableSecondaryTopbar={this.enableSecondaryTopbar}/>,
            centerTopBarComponent: this.createTopbarCenterElement(),
            enableSecondaryTopBar: secondaryTopBarEnabled
        });
        this.setLoading(false, "PatientListScreen - componentDidMount")
    }

    enableSecondaryTopbar = (input, name, surname) => {
        this.setState({secondaryTopBarEnabled: input, name, surname, centerTopBarComponent: this.createTopbarCenterElement(name,surname),})
    }

    createTopbarCenterElement = (name, surname) => {
        let {history} = this.props;
        return (
            <Chip label={name + " " + surname} color={"primary"} style={{borderRadius: "5px"}} onDelete={() => {
                history.goBack();
            }}/>
        )
    }

}


export default withRouter(PatientListScreen);
