import BaseAxios from "./BaseAxios";

export const COMMON_DELETE = async (setLoading, setSnackbarOpen, id, refreshFunction, urlAPI, errorMessage, globalContext,resetData) => {
    let res;
        let {resetDialogState, showDialog} = globalContext;
    try {
        setLoading(true, "COMMON_DELETE - submit");
        showDialog(false);
        res = await BaseAxios.request({
            url: urlAPI + id,
            method: "DELETE"//TODO cambiare in PUT e gestirlo con archiviazione
        });
        resetDialogState();
        resetData()
    } catch (error) {
        console.error("Errore deleting doctor", error.response);
        showDialog(true)
        setSnackbarOpen(
            "top",
            "right",
            true,
            2000,
            errorMessage,
            "error",
            error.response.data.uuid ? error.response.data.uuid : error.response.status + error.response.statusText
        );
    } finally {
        await refreshFunction();
        setLoading(false, "COMMON_DELETE - submit");
    }
};
